<script setup>
import FileUploadWrapItem from '@/components/elements/FileUploadWrapItem';
import { ref, computed, defineProps, defineEmits } from 'vue';

const inputRef = ref(null);

const props = defineProps({
  name: { required: true, type: String },
  title: { required: true, type: String },
  fileList: { required: false, type: Array, default: () => [] },
  iconName: { required: false, type: String, default: '' },
  subTitle: { required: false, type: String, default: '' },
  disabledInput: { type: Boolean, required: false, default: false },
});

const emits = defineEmits([ 'uploadFile' ]);

const uploadFile = () => {
  const file = inputRef.value.files[0];
  if ( file ) {
    const payload = {
      type: inputRef.value.getAttribute('data-type'),
      file,
    };
    emits('uploadFile', payload);
  }
};

const inputUploaderDisabledClass = computed(() => props.disabledInput ? 'uDisabled' : '');

const idName = computed(() => `${ props.name }_${ Math.floor(Math.random() * 10000000000) }`);
/*
          accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .xls;capture=camera"
          style="display:none"

*/
</script>


<template>
  <div class="elz d-block grow fb480">
    <label :for="idName">
      <div
          class="elz p-rel d-block r3 p16 bor1 cur-pointer opAct07 bg bg-primary bgL-5 bgHovL-10 bgLInvD br br-primary brL-10 brLInvD"
          :class="[inputUploaderDisabledClass]"
      >
        <input
            class="elz elzInputFile p-abs z1 p-EA s100p op0 cur-pointer"
            :id="idName"
            accept="image/jpeg, image/png, image/gif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ref="inputRef"
            :data-type="name"
            type="file"
            :disabled="disabledInput"
            @input="uploadFile"
        >
        <div class="elz p-abs p-F m8 bor1 br-dashed br br-primary brLF-30 brFInvD brLInvD"/>
        <div class="elz p-rel d-block al-center lh15">
          <div
              v-if="iconName"
              class="elz p-rel d-block p-rel mHAuto mB8 mskBef s32 cFillBef bgBef-CC"
              :style="`--elzMsk: url('/style/icons/${iconName}.svg');`"
          />
          <div class="elz d-block">{{ title }}</div>
          <div
              v-if="subTitle"
              class="elz d-block fn12"
          >{{ subTitle }}
          </div>
        </div>
      </div>
    </label>

    <div
        v-if="fileList"
        class="elz d-flex dir-y gap8 mT8 fn12 lh15"
    >
      <template
          v-for="fileItem in fileList"
          :key="fileItem.file_id"
      >
        <FileUploadWrapItem :file-item="fileItem"/>
      </template>
    </div>
  </div>
</template>
