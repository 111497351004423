import AppDataServ from '@/services/AppDataServ';

export const ModuleStatic = () => {
  return {
    namespaced: true,

    state: () => ({
      theme: 'elzTheme-dark',

      developersList: [
        997,   // Пустовит
        1028,  // Драгушин
        3337,  // Федулов
        3358,  // Пуликова
        3434,   // Рагозин
        3595,   // Горбачев
      ],

      userIsDeveloper: false,

      /*			headerIcons: {
				case: 0,
				clock: 0
			},*/

      user: {},

      userRights: 'user',

      filters: {},

      hydraServicesTypes: [],

      hydraPhoneOperators: [],

      hydraPhonePbx: [],

      oneTimeServices: [],

      responsibleList: [],

      scoreServices: [],

      visitStatuses: [],

    }),

    mutations: {
      AUTH_USER_FALSE(state, comment) {
        state.user = {};
        state.user.error = comment;
      },

      AUTH_USER_TRUE(state, data) {
        state.user = data.userData;
        state.userRights = data?.rights || 'user';
        localStorage.setItem('engineerAccountAppToken', data.token);
        localStorage.setItem('engineerAccountAppUserData', JSON.stringify(data.userData));
        localStorage.setItem('engineerAccountAppUserRights', JSON.stringify(state.userRights));
      },

      APP_STATIC_UPDATE(state) {
        const userData = localStorage.getItem('engineerAccountAppUserData');
        state.user = JSON.parse(userData);

        const userRights = localStorage.getItem('engineerAccountAppUserRights');
        state.userRights = JSON.parse(userRights);
      },

      SET_API_BASE_URL(state, data) {
        state.baseUrl = data;
      },

      SET_FILTERS(state, data) {
        const departments = data?.department?.list;

        if ( departments ) {
          let levels = [];
          let spaces = '';

          for ( let a = 0; a <= +data.department.levelCount; a++ ) {
            levels.push({level: a, spaces: spaces});
            spaces += '&nbsp;&nbsp;&nbsp;&nbsp;';
          }

          departments.forEach(i => {
            i.name = levels[+i.level].spaces + i.name;
            i.value = (+i.level === 0) ? 0 : i.departmentId;
          });

          data.department = departments;

          // priority 4 (низкий) = 0 (по TTS)
          data.priorities[0].value = '0';
          data.priorities[1].value = '4';

          state.filters = data;
          state.filters.readyState = true;

          localStorage.setItem('engineerAccountAppFilters', JSON.stringify(state.filters));
        }
      },

      /*SET_HEADER_ICONS(state, data) {
				console.log(state);
				console.log(data);
			},*/

      SET_HYDRA_SERVICES_LIBRARIES(state, data) {
        console.log(data);

        const list = [];

        data.forEach(el => {
          // если телефония, то извлечь библиотеку "Операторы"
          if ( +el.typeOfService === 1 ) {
            state.hydraPhoneOperators = el.operator.map(({VALUE, NAME}) => ({value: VALUE, name: NAME}));
            state.hydraPhoneOperators.unshift({
              value: '',
              name: 'Выбрать оператора',
            });
            localStorage.setItem('engineerAccountAppHydraPhoneOperators', JSON.stringify(state.hydraPhoneOperators));

            state.hydraPhonePbx = el.pbx.map(({VALUE, NAME}) => ({value: VALUE, name: NAME}));
            state.hydraPhonePbx.unshift({
              value: '',
              name: 'Указать привязку к pbx',
            });
            localStorage.setItem('engineerAccountAppHydraPhonePbx', JSON.stringify(state.hydraPhonePbx));
          }

          // извлечь библиотеку "Типы услуг"
          const t = {
            typeOfService: el.typeOfService,
          };
          t.list = el.list.map(({VALUE, NAME}) => ({value: VALUE, name: NAME}));
          t.list.unshift({
            value: '',
            name: +el.typeOfService === 1 ? 'Выбрать тарифную зону' : 'Выбрать тип услуги',
          });

          list.push(t);
        });

        state.hydraServicesTypes = list;
        localStorage.setItem('engineerAccountAppHydraServicesTypes', JSON.stringify(list));
      },

      SET_ONE_TIME_SERVICES(state, data) {
        data.forEach(el => {
          el.costs.sort((a, b) => {
            return a.cost - b.cost;
          });
        });
        state.oneTimeServices = data;
        localStorage.setItem('engineerAccountAppOneTimeServices', JSON.stringify(data));
      },

      SET_VISIT_STATUSES(state, data) {
        state.visitStatuses = data;
        localStorage.setItem('engineerAccountAppVisitStatuses', JSON.stringify(state.visitStatuses));
      },

      SET_SCORE_SERVICES(state, data) {
        state.scoreServicesRaw = data.services;
        state.workStatuses = data.workStatuses;

        const servicesDict = data.services;
        const serviceCategories = data.serviceCategories;
        const servicesMap = {};
        let services = [];

        servicesDict.forEach(({ScoreServiceCategoryID, ScoreServiceID, ServiceTitle, ServiceScore}) => {
          (servicesMap[ScoreServiceCategoryID] = (servicesMap[ScoreServiceCategoryID] || [])).push({
            id: ScoreServiceID,
            name: ServiceTitle,
            value: ServiceScore,
          });
        });

        serviceCategories.forEach(({ScoreServiceCategoryID, CategoryTitle}) => {
          services.push({
            categoryId: ScoreServiceCategoryID,
            name: CategoryTitle,
            list: servicesMap[ScoreServiceCategoryID] || [],
          });
        });

        state.scoreServices = services;

        localStorage.setItem('engineerAccountAppScoreServicesRaw', JSON.stringify(state.scoreServicesRaw));
        localStorage.setItem('engineerAccountAppScoreServices', JSON.stringify(state.scoreServices));
        localStorage.setItem('engineerAccountAppWorkStatuses', JSON.stringify(data.workStatuses));
      },

      SET_USER_DEVELOPER_STATUS(state) {
        state.userIsDeveloper = state.developersList.includes(state.user.userId);
      },

      STATE_CLEAR(state) {
        state.user = {};
        state.filters = {};
        state.responsibleList = [];
        state.visitStatuses = [];
        state.scoreServices = [];
        state.hydraServiceTypes = [];
        state.hydraPhoneOperators = [];
        state.hydraPhonePbx = [];
        state.oneTimeServices = [];

        localStorage.removeItem('engineerAccountAppUserData');
        localStorage.removeItem('engineerAccountAppFilters');
        localStorage.removeItem('engineerAccountAppVisitStatuses');
        localStorage.removeItem('engineerAccountAppWorkStatuses');
        localStorage.removeItem('engineerAccountAppScoreServices');
        localStorage.removeItem('engineerAccountAppScoreServicesRaw');
        localStorage.removeItem('engineerAccountAppHydraServicesTypes');
        localStorage.removeItem('engineerAccountAppHydraPhoneOperators');
        localStorage.removeItem('engineerAccountAppHydraPhonePbx');
        localStorage.removeItem('engineerAccountAppOneTimeServices');
        localStorage.removeItem('engineerAccountAppToken');
      },

      STATE_SET_THEME(state) {
        state.theme = localStorage.getItem('engineerAccountAppThemeSettings');

        if ( !state.theme ) {
          localStorage.setItem('engineerAccountAppThemeSettings', 'elzTheme-dark');
          state.theme = 'elzTheme-dark';
        }
      },

      UPDATE_RESPONSIBLE(state, data) {
        state.responsibleList.push(data[0]);
      },

      UPDATE_THEME(state, theme) {
        state.theme = theme;
        localStorage.setItem('engineerAccountAppThemeSettings', theme);
      },
    },

    actions: {
      getApiBaseUrl({commit}) {
        commit('SET_API_BASE_URL', AppDataServ.getApiBaseUrl());
      },

      fetchAuthUser({commit}, params) {
        if ( params.login && params.password ) {
          return AppDataServ.authUser(params)
            .then(response => {
              if ( response.userData ) {
                commit('AUTH_USER_TRUE', response);
              } else if ( +response.status === 401 ) {
                commit('AUTH_USER_FALSE', 'Неверный логин или пароль');
              } else {
                commit('AUTH_USER_FALSE', 'Ошибка подключения к удаленному серверу');
              }
            });
        } else {
          commit('AUTH_USER_FALSE', 'Данные для авторизации не заполнены');
        }
      },

      fetchAuthUserToken({commit}) {
        if ( localStorage.engineerAccountAppToken && localStorage.engineerAccountAppUserData ) {
          commit('APP_STATIC_UPDATE');
          AppDataServ.updateToken(localStorage.engineerAccountAppToken);
        }
      },

      fetchFilters({commit, state}) {
        const f = localStorage.getItem('engineerAccountAppFilters');
        state.filters = JSON.parse(f) || [];

        if ( !state.filters.length ) {
          return AppDataServ.getFilterData()
            .then(response => {
              commit('SET_FILTERS', response.data);
            })
            .catch(error => {
              throw (error);
            });
        }
      },

      fetchHydraServicesTypes({commit, state}) {
        const hst = localStorage.getItem('engineerAccountAppHydraServicesTypes');
        state.hydraServicesTypes = JSON.parse(hst) || [];

        const hpo = localStorage.getItem('engineerAccountAppHydraPhoneOperators');
        state.hydraPhoneOperators = JSON.parse(hpo) || [];

        const pbx = localStorage.getItem('engineerAccountAppHydraPhonePbx');
        state.hydraPhonePbx = JSON.parse(pbx) || [];

        if ( !state.hydraServicesTypes.length || !state.hydraPhoneOperators.length || !state.hydraPhonePbx.length ) {
          return AppDataServ.getHydraServicesTypes()
            .then(response => {
              commit('SET_HYDRA_SERVICES_LIBRARIES', response.data);
            })
            .catch(error => {
              throw (error);
            });
        }
      },

      fetchOneTimeServices({commit, state}) {
        const ots = localStorage.getItem('engineerAccountAppOneTimeServices');
        state.oneTimeServices = JSON.parse(ots) || [];

        if ( !state.oneTimeServices.length ) {
          return AppDataServ.getOneTimeServices()
            .then(response => {
              commit('SET_ONE_TIME_SERVICES', response.data);
            })
            .catch(error => {
              throw (error);
            });
        }
      },

      /*			fetchHeaderIcons({ commit }) {
				return AppDataServ.getHeaderIcons()
					.then(response => {
						commit('SET_HEADER_ICONS', response.data);
					})
					.catch(error => {
						throw(error);
					});
			},*/

      fetchResponsible({commit}, responsibleId) {
        return AppDataServ.getResponsibleDetails(responsibleId)
          .then(response => {
            commit('UPDATE_RESPONSIBLE', response.data);
          })
          .catch(error => {
            throw (error);
          });
      },

      fetchScoreServices({commit, state}) {

        const currentVersion = 2;

        let needFetchData = false;

        const dictVersions = {
          ssr: {lsKey: 'engineerAccountAppScoreServicesRaw', storeKey: 'scoreServicesRaw', version: currentVersion},
          ss: {lsKey: 'engineerAccountAppScoreServices', storeKey: 'scoreServices', version: currentVersion},
          ws: {lsKey: 'engineerAccountAppWorkStatuses', storeKey: 'workStatuses', version: currentVersion},
        };

        if ( !localStorage.engineerAccountAppDictionariesVersions ) {

          needFetchData = true;

        } else {

          const lsDictVersions = JSON.parse(localStorage.getItem('engineerAccountAppDictionariesVersions'));

          for ( const key in dictVersions ) {

            const lsKey = dictVersions[key].lsKey;
            const storeKey = dictVersions[key].storeKey;
            const ver = dictVersions[key]?.version;

            if ( localStorage[lsKey] && lsDictVersions[key].version === ver ) {

              state[storeKey] = JSON.parse(localStorage[lsKey]) || [];

            } else {
              needFetchData = true;
            }
          }
        }

        if ( needFetchData ) {
          return AppDataServ.getScoreServices()
            .then(response => {
              commit('SET_SCORE_SERVICES', response.data);

              localStorage.setItem('engineerAccountAppDictionariesVersions', JSON.stringify(dictVersions));

            })
            .catch(error => {
              throw (error);
            });
        }


        // if ( localStorage.engineerAccountAppScoreServicesRaw && localStorage.engineerAccountAppScoreServices && localStorage.engineerAccountAppWorkStatuses ) {
        //
        //   const ssr = localStorage.getItem('engineerAccountAppScoreServicesRaw');
        //   const ss = localStorage.getItem('engineerAccountAppScoreServices');
        //   const ws = localStorage.getItem('engineerAccountAppWorkStatuses');
        //
        //   state.scoreServicesRaw = JSON.parse(ssr) || [];
        //   state.scoreServices = JSON.parse(ss) || [];
        //   state.workStatuses = JSON.parse(ws) || [];
        // } else {
        //   return AppDataServ.getScoreServices()
        //     .then(response => {
        //       commit('SET_SCORE_SERVICES', response.data);
        //
        //       localStorage.setItem('engineerAccountAppDictionariesVersions', JSON.stringify(dictVersions));
        //
        //     })
        //     .catch(error => {
        //       throw(error);
        //     });
        // }
      },

      fetchVisitStatuses({commit, state}) {
        const vs = localStorage.getItem('engineerAccountAppVisitStatuses');
        state.visitStatuses = JSON.parse(vs) || [];

        if ( !state.visitStatuses.length ) {
          return AppDataServ.getVisitStatuses()
            .then(response => {
              commit('SET_VISIT_STATUSES', response.data);
            })
            .catch(error => {
              throw (error);
            });
        }
      },
    },

    getters: {
      isAdmin: (state) => state.userRights === 'admin',
    },
  };
};
