<template>
  <a
    :href="'#'+bookmark"
    :class="wrapperClass"
    class="elz montMenuItem p-rel d-flex grow al-center nowrap noShrink cur-pointer opAct07 scHovOut scSelOut opHovOut opSelOut"
    @click="$emit('onItemClick')"
  >
    <div class="elz d-flex a-X grow gap8 pH16">
      <div
        class="elz p-rel d-block p-rel mskBef s16 cFillBef bgBef-CC"
        :style="`--elzMsk: url('/style/icons/${iconName}.svg');`"
      />
      <div class="elz d-block">{{ title }}</div>
      <div
        v-if="label"
        class="elz d-block p4 al-center wmn24 r3 fn11 bold bg bg-primary bgL-10"
      >{{ label }}</div>
      <div class="elz p-abs p-AB d-block h3 mB-2 mH16 rRound scZeroX scHovInFull scSelInFullX op0 opHovIn05 opSelIn10 trnsOccur trns2 bg-CC" />
    </div>
  </a>
</template>

<script>
import { ref, computed } from 'vue';

export default {
  name: "OrderNavItem",

  props: {
    bookmark: { required: true,  type: String },
    iconName: { required: true,  type: String },
    title:    { required: true,  type: String },
    label:    { required: false, type: [ Number, String ], default:undefined },
    disabled: { required: false, type: Boolean }
  },

  emits: [ 'onItemClick' ],

  setup(props) {
    let isActive = ref(false);

    const wrapperClass = computed(() => {
      let classList = '';

      if (props.disabled) {
        classList += 'uDisabled ';
      }

      if (isActive.value) {
        classList += 'sel fn fn-success ';
      }

      return classList;
    });

    return { isActive, wrapperClass }
  },

  /*  data() {
    return {
      isActive: false
    }
  },*/

/*  computed: {
    wrapperClass() {
      let classList = '';

      if (this.disabled) {
        classList += 'uDisabled ';
      }

      if (this.isActive) {
        classList += 'sel fn fn-success ';
      }

      return classList;
    }
  }*/
}
</script>
