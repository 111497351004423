<template>
  <div class="elz d-block mV24 mAuto borV1 br br-primary brL-10 brLInvD">
    <div class="elz d-flex pV2 mV-2 oAuto scrollHide uDragToScroll cur-grabAct">
      <div class="elz d-flex h56 w1200 wmx100p mAuto">
        <OrderNavItem
          ref="works"
          :bookmark="'works'"
          :icon-name="'hammer1'"
          :title="'Работы по заявке'"
          :label="$store.state.scoreWorks.works.length"
          @onItemClick="navClick('works')"
        />

        <template v-if="isConnection">
          <OrderNavItem
            ref="customer"
            :bookmark="'customer'"
            :icon-name="'user'"
            :title="'Данные клиента'"
            :disabled="!onPlace"
            @onItemClick="navClick('customer')"
          />

          <OrderNavItem
            ref="services"
            :bookmark="'services'"
            :icon-name="'hammer-wrench'"
            :title="'Конфигурация услуг'"
            :disabled="disableServices"
            @onItemClick="navClick('services')"
          />

          <OrderNavItem
            ref="finishing"
            :bookmark="'finishing'"
            :icon-name="'file-text'"
            :title="'Завершение'"
            :disabled="!onPlace"
            @onItemClick="navClick('finishing')"
          />
        </template>

        <template v-else>
          <OrderNavItem
            ref="files"
            :bookmark="'files'"
            :icon-name="'file-text'"
            :title="'Файлы'"
            @onItemClick="navClick('files')"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import OrderNavItem from "@/components/order/OrderNavItem";

export default {
  name: "OrderNav",

  components: {
    OrderNavItem
  },

  props: {
    isConnection: { required: true, type: Boolean },
    noUBN:        { required: true, type: Boolean },
    onPlace:      { required: true, type: Boolean },
    hash:         { required: false, type: String, default:undefined }
  },

  emits: [ 'switchOrderContent' ],

  computed: {
    disableServices() {
      return this.noUBN ? true : !this.onPlace;
    }
  },

  mounted() {
    this.hash ? this.$refs[this.hash].isActive = true : this.$refs[Object.keys(this.$refs)[0]].isActive = true;
  },

  methods: {
    navClick(bookmarkName) {
      Object.keys(this.$refs).forEach(el => {
        (el === bookmarkName) ? this.$refs[el].isActive = true : this.$refs[el].isActive = false;
      });
      this.$emit('switchOrderContent', bookmarkName);
    },
  }

}
</script>

<style scoped>
.montMenuItem:only-child {
  flex-grow: 0;
}
</style>
