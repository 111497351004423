<template>
  <template v-if="isActive && dataIsReady">
    <div
      :class="{sel: isOpened}"
      class="elz d-block r3 bor1 fn12 mB32 oAuto br br-ok bg bg-ok bgA10 showSelOut hideSelOut"
    >
      <div
        class="elz d-flex gap8 a-H p16 fn16 opAct07 cur-pointer"
        @click="isOpened = !isOpened"
      >
        <div class="elz d-block grow bold">
          Услуги по сделке: #{{ dealId }}
        </div>
        <div
          class="elz p-rel d-block noShrink p-rel mskBef s8 deg180 cFillBef bgBef-CC hideSelIn"
          style="--elzMsk: url('/style/icons/arrow1.svg');"
        />
        <div
          class="elz p-rel d-block noShrink p-rel mskBef s8 cFillBef bgBef-CC showSelIn"
          style="--elzMsk: url('/style/icons/arrow1.svg');"
        />
      </div>
      <table class="elz elzTable w100p showSelIn">
        <thead class="tbody bor borB1 br br-ok">
          <tr class="tr bold pad pV10 pH16 nowrap">
            <td class="td wmn200">
              Бизнес-услуга
            </td>
            <td class="td wmn200">
              Наименование в счете
            </td>
            <td class="td">
              Вид начисления
            </td>
            <td class="td">
              Сумма по сделке
            </td>
          </tr>
        </thead>
        <tbody class="tbody pad pV10 pH16 fn14 uStrip stripEven strip005 stripHover stripLD va-M lh15">
          <tr
            v-for="(service,index) in $store.state.orderPage.services.fromSD"
            :key="index"
            class="tr"
          >
            <td class="td">
              {{ service.businessName }}
            </td>
            <td class="td">
              {{ service.billName }}
            </td>
            <td class="td">
              {{ service.incomingType }}
            </td>
            <td class="td nowrap">
              {{ service.sum }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="elz d-flex dir-y gap16">
      <div
        v-if="accountData"
        class="elz d-block p-rel bor1 r3 mB16 bg bg-primary bgL5 br br-primary brL-10 brLInvD hideSelOut showSelOut"
      >
        <div class="elz d-flex f-wrap gap8 a-H p16 hmn64">
          <div class="elz d-flex f-wrap a-H grow gap8 fb150">
            <div class="elz d-block grow fb320 lh12">
              <div class="elz d-block bold">
                Личный кабинет
              </div>
            </div>
          </div>
          <div class="empLogPassWrap elz d-flex fb400">
            <div class="empLogPassItem elz p-rel d-flex a-X fb200">
              <Tooltip
                ref="login"
                :type="'ok'"
                :has-tail="true"
                :duration="1500"
              >
                Логин скопирован
              </Tooltip>
              <InputBase
                :model-value="accountData.login"
                :input-type="'text'"
                :readonly="true"
                :icon="'user'"
                :class-input="'rL3 selNone cur-inh h32 w100p '"
                :class-label="'empLogPassInput grow mR-1 cur-pointer'"
                @on:click="(e) => showTooltip('login', e.currentTarget.querySelector('input'))"
              />
            </div>
            <div class="empLogPassItem elz p-rel d-flex a-X fb200">
              <Tooltip
                ref="password"
                :type="'ok'"
                :has-tail="true"
                :duration="1500"
              >
                Пароль скопирован
              </Tooltip>
              <InputBase
                :model-value="accountData.pass"
                :input-type="'password'"
                :readonly="true"
                :icon="'key'"
                :class-input="'rR3 selNone cur-inh h32 w100p '"
                :class-label="'empLogPassInput grow cur-pointer'"
                @on:click="(e) => showTooltip('password', e.currentTarget.querySelector('input'))"
              />
            </div>
          </div>
          <div
            class="elz d-flex a-X wmn120 hmn28 p8 r3 fn11 bold bg cur-help bg-success fn fn-success-t"
            title="Доступ к личному кабинету предоставлен"
          >
            Подключен
          </div>
        </div>
      </div>

      <template
        v-for="(service, idx) in servicesHydra"
        :key="idx"
      >
        <template v-if="+service.typeOfService === 1">
          <OrderServicesHydraPhone
            :service="service"
            @changeZone="(val, zone) => changeType(idx, val, zone)"
            @createConnection="createConnection(idx)"
            @setTarifficationPhone="(params) => setTarifficationPhone(idx, params)"
            @toggleServiceView="servicesHydra[idx].isOpened = !servicesHydra[idx].isOpened"
          />
        </template>

        <template v-else>
          <OrderServicesHydra
            :data-is-ready="dataIsReady"
            :service="service"
            @changeTariff="(val) => service.tariff = val"
            @changeType="(val) => changeType(idx, val, order.TariffZoneSDId)"
            @createConnection="createConnection(idx)"
            @setTariffication="(tariff, contract) => setTariffication(idx, tariff, contract)"
            @toggleServiceView="servicesHydra[idx].isOpened = !servicesHydra[idx].isOpened"
          />
        </template>
      </template>

      <!--      <template v-if="$store.state.static.userIsDeveloper">-->
      <OrderServicesOneTime
        :deal-id="+dealId"
        :customer-sd-id="+order.CustomerIDSD"
      />
      <!--      </template>-->
    </div>
  </template>

  <template v-if="showUploader">
    <Uploader
      :circle-size="'s100'"
      :circle-width="'2'"
      :view-settings="'p-abs p16 r3 z10 bg bg-primary bgL5 br br-primary brL-10 brLInvD bgA50'"
    />
  </template>
</template>

<script>
import Tooltip from '@/components/elements/Tooltip';
import InputBase from '@/components/elements/InputBase';
import OrderServicesHydra from '@/components/order/OrderServicesHydra';
import OrderServicesHydraPhone from '@/components/order/OrderServicesHydraPhone';
import OrderServicesOneTime from '@/components/order/OrderServicesOneTime';
import showTooltip from '@/mixins/showTooltip';
import { useStore } from 'vuex';

export default {
  name: 'OrderServices',

  components: {
    Tooltip,
    InputBase,
    OrderServicesHydra,
    OrderServicesHydraPhone,
    OrderServicesOneTime,
  },

  mixins: [ showTooltip ],

  setup() {
    const store = useStore();

    const dealId = store.state.orderPage.order.details.DealID;

    store.dispatch('static/fetchHydraServicesTypes');

    store.dispatch('static/fetchOneTimeServices');

    store.dispatch('orderPage/fetchSDServices', dealId);

    store.dispatch('orderPage/fetchHydraServices', dealId);

    return { dealId };
  },

  data() {
    return {
      isActive: false,
      isOpened: false,
      showUploader: false,
    };
  },

  computed: {
    accountData() {
      return this.$store.state.orderPage.services.account;
    },

    dataIsReady() {
      return this.$store.state.orderPage.services.readyState && this.$store.state.static.hydraServicesTypes.length > 0 && this.$store.state.static.oneTimeServices.length > 0;
    },

    servicesHydra() {
      return this.$store.state.orderPage.services.fromHydra;
    },

    order() {
      return this.$store.state.orderPage.order.details;
    },
  },

  watch: {
    dataIsReady() {
      this.showUploader = !this.dataIsReady && this.isActive;
    },
  },

  methods: {
    async changeType(idx, val, zone) {
      const item = this.servicesHydra[idx];
      item.showUploader = true;

      await this.$store.dispatch('orderPage/updateTypeServices', {
        tariffZone: zone,
        typeOfMainService: val,
        mainServiceSdId: item.typeOfService,
        customerHydraId: this.order.CustomerUBN,
      });

      item.showUploader = false;
    },

    async createConnection(idx) {
      const item = this.servicesHydra[idx];
      item.showUploader = true;

      await this.$store.dispatch('orderPage/setHydraConnection', {
        goodName: item.name,
        customerHydraId: this.order.CustomerUBN,
      });

      item.isConnected = true;
      item.isOpened = true;
      item.showUploader = false;
    },

    async setTarifficationPhone(idx, {
      contract,
      tariff,
      operator,
      pbx,
      phone,
      tariffZone,
      serviceTypeName,
      serviceName,
      serviceAmount,
      operatorContractName,
      pbxName,
    }) {
      const item = this.servicesHydra[idx];
      item.showUploader = true;

      await this.$store.dispatch('orderPage/setTarifficationPhone', {
        serviceId: tariff,
        customerId: this.order.CustomerUBN,
        accountId: 0, //если есть, если нет то 0
        objectId: 0,  //если есть, если нет то 0

        baseContractHydraId: contract, //передаю теперь в hydraworker/serviceConfig **
        mainServiceSdId: item.typeOfService,
        orderTTSId: this.order.OrderID,
        orderSdId: this.order.DealID,
        operatorContractId: operator,
        pbxId: pbx,
        phoneNumber: phone,

        tariffZone: tariffZone,
        serviceTypeName: serviceTypeName,
        serviceName: serviceName,
        serviceAmount: serviceAmount,
        operatorContractName: operatorContractName,
        pbxName: pbxName,
      });

      item.billingStart = true;
      item.showUploader = false;
    },

    async setTariffication(idx, tariff, contract) {
      const item = this.servicesHydra[idx];
      item.showUploader = true;

      const serviceTypes = this.$store.state.static.hydraServicesTypes.find(({ typeOfService }) => +typeOfService === +item.typeOfService).list;
      const tariffData = this.servicesHydra[idx].tariffList.find(el => +el.value === +tariff);

      await this.$store.dispatch('orderPage/setTariffication', {
        serviceId: tariff,
        customerId: this.order.CustomerUBN,
        accountId: 0, //если есть, если нет то 0
        objectId: 0,  //если есть, если нет то 0
        baseContractHydraId: contract, //передаю теперь в hydraworker/serviceConfig **
        mainServiceSdId: item.typeOfService,
        orderTTSId: this.order.OrderID,
        orderSdId: this.order.DealID,
        serviceName: tariffData.name,
        serviceAmount: tariffData.price,
        serviceTypeName: serviceTypes.find(el => +el.value === +item.serviceTypeId).name,
      });

      item.billingStart = true;
      item.showUploader = false;
    },
  },
};
</script>

<style>
@media (max-width: 720px) {
  .empLogPassWrap {
    flex-basis: 100%;
  }
}

@media (max-width: 480px) {
  .empLogPassWrap {
    flex-wrap: wrap;
  }

  .empLogPassItem {
    flex-basis: 100%;
  }

  .empLogPassItem:not(:last-child) .d-grid {
    margin-right: 0;
    margin-bottom: -1px;
  }

  .empLogPassItem:first-child .elzInput {
    border-radius: 3px 3px 0 0;
  }

  .empLogPassItem:last-child .elzInput {
    border-radius: 0 0 3px 3px;
  }

  .empLogPassItem:not(:last-child):not(:first-child) .elzInput {
    border-radius: 0;
  }

}
</style>