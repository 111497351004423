<template>
  <div class="elz p-rel d-flex grow fb280 dir-y gap16 lh12">
    <ButtonBase
        :icon-name="'truck'"
        :class-list="[{uDisabled: order.meetingStatusId > 2},' hmn48 grow bg-primary bgL-5 bgLInvD bgHovL-10']"
        :title="formattedDateTime"
        @click="datepicker = true"
    />

    <ButtonBase
        :icon-name="onWayConfirm ? 'question' : 'location1'"
        :class-list="[{uDisabled: order.meetingStatusId > 2 || !order.meetingDateTime}, 'onWayConfirm hmn48 grow bg-ok bgHovL10 fn-ok-t']"
        :title="onWayConfirm ? 'Подтвердить выезд' : titleOnWay"
        @onButtonClick="confirmAction('onWayConfirm', 'onWayDateTime', 3, )"
    />

    <ButtonBase
        :icon-name="onPlaceConfirm ? 'question' : 'flag'"
        :class-list="[{uDisabled: order.meetingStatusId < 3 || order.meetingStatusId >= 4}, 'onPlaceConfirm hmn48 grow bg-success bgHovL10 fn-ok-t']"
        :title="onPlaceConfirm ? 'Подтвердить прибытие' : titleOnPlace"
        @onButtonClick="confirmAction('onPlaceConfirm', 'onPlaceDateTime', 4)"
    />

    <template v-if="datepicker">
      <PopUpWindow
          :class-name="'p-AT mT-8 mH-16'"
          @closePopUp="datepicker = false"
      >
        <DateTimePicker
            :current-date="order.meetingDateTime"
            @datepickerDate="updateMeetingDateTime"
            @clear="clearMeetingDateTime"
        />
      </PopUpWindow>
    </template>
  </div>
</template>


<script>
import DateTimePicker from '@/components/elements/DateTimePicker';
import PopUpWindow from '@/components/elements/PopUpWindow';
import ButtonBase from '@/components/elements/ButtonBase';
import clickOut from '@/mixins/clickOut';
// import { dateFormatDdMmYyyy, dateTimeFormatHHMM } from '@/helpers/formating';
import moment from 'moment';

export default {
  name: 'OrderHeaderConnection',

  components: {
    ButtonBase,
    DateTimePicker,
    PopUpWindow,
  },

  mixins: [ clickOut ],

  data() {
    return {
      onWayConfirm: false,
      onPlaceConfirm: false,
      datepicker: false,
    };
  },

  computed: {
    formattedDateTime() {
      if ( this.order.meetingDateTime ) {
        const meetingMoment = new moment(this.order.meetingDateTime);
        if ( meetingMoment.isValid() ) {
          return meetingMoment.format('DD.MM.YYYY в HH:mm');
        }
      }
      return 'Дата и время не заданы';
      // return this.order.meetingDateTime ? `${ dateFormatDdMmYyyy(this.order.meetingDateTime) } в ${ dateTimeFormatHHMM(this.order.meetingDateTime) }` : 'Дата и время не заданы';
    },

    order() {
      return this.$store.state.orderPage.order.details;
    },

    titleOnPlace() {
      if ( this.order.meetingStatusId >= 4 ) {
        const onPlaceMoment = new moment(this.order.onPlaceDateTime);
        if ( onPlaceMoment.isValid() ) {
          return onPlaceMoment.format('Прибыл в HH:mm');
        }
      }

      return 'Прибыл на место';
      // return this.order.meetingStatusId >= 4 && this.order.onPlaceDateTime ? `Прибыл в ${ dateTimeFormatHHMM(this.order.onPlaceDateTime) }` : 'Прибыл на место';
    },

    titleOnWay() {
      if ( this.order.meetingStatusId >= 3 ) {
        const onWayMoment = new moment(this.order.onWayDateTime);

        console.log('onWayMoment', onWayMoment.format('YY-MM-DD HH:mm'), onWayMoment.isValid(), moment.isDate(this.order.onWayDateTime));
        if ( onWayMoment.isValid() ) {
          return onWayMoment.format('Выехал в HH:mm');
        }
      }

      return 'Выехал к клиенту';
      // return this.order.meetingStatusId >= 3 && this.order.onWayDateTime ? `Выехал в ${ dateTimeFormatHHMM(this.order.onWayDateTime) }` : 'Выехал к клиенту';
    },
  },

  methods: {
    async clearMeetingDateTime() {

      await this.$store.dispatch('orderPage/updateMeetingDateTime', this.getMeetingParams(null, 1, 'meetingDateTime'));
      this.datepicker = false;
    },

    getMeetingParams(date, status, keyTime = 'meetingDateTime') {
      return {
        time: date,
        status: status,
        orderId: this.order.OrderID,
        keyTime,
      };
    },

    async confirmAction(actionName, dateName, statusId) {

      console.log('confirmAction', actionName, dateName);

      if ( !this[actionName] ) {
        this[actionName] = true;
        this.setClickOut(actionName);
      } else {
        this.order[dateName] = this.timeStampNow();
        // this.order.meetingStatusId = statusId;
        // await this.$store.dispatch('orderPage/updateMeetingDateTime', this.getMeetingParams(this.order[dateName], this.order.meetingStatusId));
        await this.$store.dispatch('orderPage/updateMeetingDateTime', this.getMeetingParams(new Date(), statusId, dateName));
        this[actionName] = false;
      }
    },

    timeStampNow() {
      const date = new Date();
      return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
    },

    async updateMeetingDateTime(date) {


      // this.order.meetingStatusId = date ? 2 : 1;
      await this.$store.dispatch('orderPage/updateMeetingDateTime', this.getMeetingParams(date, 2, 'meetingDateTime'));
      // this.order.meetingDateTime = date;
      this.datepicker = false;
    },
  },
};
</script>
