<template>
  <label
    class="elz d-grid fb200 grPos fn fn-primary-t fnL20 fnLInvD fnHovL10 fnFow-focus fnFowL0"
    :class="classLabel"
    :title="title"
    @click="$emit('on:click', $event)"
  >

    <input
      v-maska="inputMask"
      class="elz elzInput d-block grPin grY2 bor1 pL40 pR8 fn12 ellipsis trns2 invPssSib bg bg-primary bgL10 bgLInvD br brLInvD br-primary brL-10 brHovL-20 brFoc-focus brFocL0 fn fn-primary-t"
      :class="classInput"
      :placeholder="placeholder"
      :readonly="readonly"
      :type="inputType"
      :value="modelValue"
      @change="$emit('on:change', $event.target.value)"
      @keyup="$emit('on:keyup', $event.target.value, $event.target.getAttribute('data-mask-raw-value'))"
    >

    <span
      v-if="icon"
      class="elz d-flex grPin grY2 a-H bor1 pH7 z6 evNone"
    >
      <span class="elz p-rel d-flex a-X s24 trns2">
        <span
          class="elz p-rel d-block mskBef s16 cFillBef bgBef-CC"
          :style="`--elzMsk: url('/style/icons/${icon}.svg');`"
        />
      </span>
    </span>

  </label>
</template>

<script>
export default {
  name: "InputBase",

  props: {
    modelValue:  { required: true,  type: [Number, String, null] },
    inputType:   { required: true,  type: String },
    classInput:  { required: false, type: String, default:'' },
    classLabel:  { required: false, type: String, default:'' },
    icon:        { required: false, type: String, default:'' },
    inputMask:   { required: false, type: [Object, String], default:'' },
    placeholder: { required: false, type: String, default:'' },
    readonly:    { required: false, type: Boolean },
    title:       { required: false, type: String, default:'' }
  },

  emits: ['on:change', 'on:click', 'on:keyup' ]
}
</script>

<style scoped>
@media (max-width: 768px) {
  .empLogPassWrap {
    order: 2;
    width: 100%;
  }

  .empLogPassWrap .empLogPassInput { flex-grow: 1; }
}
</style>

