import axios from 'axios';
import useApiStatus from "@/use/apiStatus";

//для DEV-сервера 'https://172.16.220.252:24137'
//для PROD-сервера 'https://api-emp.naukanet.ru'
const baseUrl = (process.env.NODE_ENV === 'production') ? 'https://api-emp.naukanet.ru' : 'http://172.16.220.252:24137';

process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';

// console.log('baseUrl', process.env.NODE_ENV, baseUrl);

const apiConnection = axios.create({
  baseURL: baseUrl,
  withCredentials: false,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  // proxy: {
  //   protocol: 'https',
  //   host: 'api-emp.naukanet.ru',
  //   port: 80,
  // },
});

const {setLoading, setError, clearError} = useApiStatus();

apiConnection.interceptors.request.use((config) => {
  setLoading(true);

  return config;
}, (error) => {
  setLoading(false);

  return Promise.reject(error);
});

apiConnection.interceptors.response.use((response) => {

    clearError();

    setLoading(false);

    console.log('TODO: сюда валим обновление токена', response.headers);

    // TODO: сюда валим обновление токена

    return response;
  },
  (error) => {
    const statusCode = parseInt(error.response?.status) || 500;

    const errorMessage = error.response?.data?.message || error.code;

    console.log('TODO: переходим к авторизации если 401, иначе интерфейс ошибки должен показать errorMessage', {
      code: statusCode,
      message: errorMessage,
      error
    });

    // TODO: переходим к авторизации если 401, иначе интерфейс ошибки должен показать errorMessage


    setError({
      code: statusCode,
      message: errorMessage,
    })

    setLoading(false);


    return {}
  })


export default {

  async authUser(params) {
    try {
      const request = await apiConnection.post('/authorization', params);
      apiConnection.defaults.headers.common['token'] = request.data.token;
      return request.data;
    }
    catch (error) {
      return error.response ? error.response : {status: 404};
    }
  },

  getApiBaseUrl() {
    return apiConnection.defaults.baseURL;
  },

  createSubscriber(params) {
    return apiConnection.post('/hydraWorker/createSubscriber/', params);
  },

  deleteOrderWork(workId) {
    return apiConnection.delete(`/scoreWorks/${workId}`);
  },

  deleteOrderWorkParticipant(workId, participantId) {
    return apiConnection.delete(`/scoreWorks/${workId}/participants/${participantId}`);
  },

  deleteOrderWorkService(workId, serviceId) {
    return apiConnection.delete(`/scoreWorks/${workId}/services/${serviceId}`);
  },

  getCustomerInfo(customerId, dealId) {
    return apiConnection.get(`/customer/getInfo/${customerId}/${dealId}`);
  },

  getDownloadedFiles(orderId) {
    return apiConnection.get(`/fileLoader/dealFileList/${orderId}`);
  },

  getFilterData() {
    return apiConnection.get('/filter');
  },

  /*	getHeaderIcons() {
			console.log('API get Header Icons');
			return apiConnection.get('/order/getInfoForHeader');
		},*/

  getHydraServices(dealId) {
    return apiConnection.get(`deal/getBusinessType/${dealId}`);
  },

  getHydraServicesTypes() {
    return apiConnection.get('/hydraWorker/serviceConfig/serviceType/getList');
  },

  getOneTimeServices() {
    return apiConnection.get('/hydraWorker/oneTimeService/getList');
  },

  getOneTimeServicesConnected(dealId) {
    return apiConnection.get(`/hydraWorker/oneTimeService/getConnectedServices/${dealId}`);
  },

  getOrderDetails(orderId) {
    return apiConnection.get(`/order/getInfo/${orderId}`);
  },

  getOrderSingleSearch(orderId) {
    return apiConnection.get(`/order/getPreview/${orderId}`);
  },

  getOrderWorks(params) {
    return apiConnection.get('/scoreWorks', {params: params});
  },

  getOrderWorkDetails(workId) {
    return apiConnection.get(`/scoreWorks/${workId}`);
  },

  getOrders() {
    return apiConnection.get('/order/getList/');
  },

  getResponsibleDetails(responsibleId) {
    return apiConnection.get(`/responsibleDetails/${responsibleId}`);
  },

  getSDServices(dealId) {
    return apiConnection.get(`/deal/getServiceList/${dealId}`);
  },

  getScoreServices() {
    return apiConnection.get('/scoreWorks/dictionaries');
  },

  getVisitStatuses() {
    return apiConnection.get('/deal/getStatusList');
  },

  setOneTimeServices(params) {
    return apiConnection.post('/hydraWorker/oneTimeService/connectService', params);
  },

  setOrderWork(params) {
    return apiConnection.post('/scoreWorks', params);
  },

  setOrderWorkParticipant(workId, params) {
    return apiConnection.post(`/scoreWorks/${workId}/participants`, params);
  },

  setOrderWorkService(workId, params) {
    return apiConnection.post(`/scoreWorks/${workId}/services`, params);
  },

  setTariffication(params) {
    return apiConnection.post('/hydraWorker/createSubscription', params);
  },

  setTarifficationPhone(params) {
    return apiConnection.post('hydraWorker/createSubscription/phone', params);
  },

  /*	setWebSocketHeader(socketId) {
		apiConnection.defaults.headers.common['socket'] = socketId;
	},*/

  updateCustomerInfo(customerData) {
    return apiConnection.post('/customer/updateInfo', customerData);
  },

  updateCustomerUBN(customerSdId) {
    return apiConnection.get(`customer/hydraBinding/${customerSdId}`);
  },

  updateMeetingDateTime(params) {
    return apiConnection.post('/deal/updateDealInfo', params);
  },

  updateOrderWorkFinished(workId, params) {
    return apiConnection.patch(`/scoreWorks/${workId}/finish`, params);
  },

  restoreOrderWorkFinished(workId) {
    return apiConnection.patch(`/scoreWorks/${workId}/edit`);
  },

  updateOrderWorkParticipant(workId, participantId) {
    return apiConnection.patch(`/scoreWorks/${workId}/participants/${participantId}/pause`);
  },

  updateOrderWorkService(workId, serviceId, params) {
    return apiConnection.patch(`scoreWorks/${workId}/services/${serviceId}`, params);
  },

  updateOrderWorkStarted(workId, params) {
    return apiConnection.patch(`/scoreWorks/${workId}/start`, params);
  },

  updateToken(token) {
    apiConnection.defaults.headers.common['token'] = token;
  },

  updateTypeServices(params) {
    return apiConnection.post('/hydraWorker/serviceConfig/postConnectionType', params);
  },

  uploadFileToServer(file) {
    return apiConnection.post(
      '/fileLoader',
      file,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },

  getWorksDownloadedFiles(workId) {
    return apiConnection.get(`/fileLoader/getFileWorkById/${workId}`);
  },

};


