export default {

  methods: {
    clickOut(actionName, e) {
      if (this[actionName] === false || !e.target.closest(`.${actionName}`)) {
        this[actionName] = false;
      }
    },

    // actionName - имя класса, к которому привязываемся для проверки html-элемента на дабл-клик
    setClickOut(actionName) {
      document.addEventListener("click", this.clickOut.bind(null, actionName), { capture: true, once:true });
    }
  }

}
