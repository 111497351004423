<template>
  <div class="elz d-flex f-wrap a-H uStripIn pR16">
    <label class="elz d-flex a-H gap16 growMax fb280 pL16 pV8 cur-pointer">
      <input
        :value="item.id"
        :checked="isSelected"
        type="checkbox"
        class="elz elzCheck checkbox p-rel d-flex noShrink cur-pointer bor1 s18 p3 r2 cLInvD bg bg-primary bgL10 br br-primary brL-10 brHovL-20 fn fn-primary-t fnHovL-5 bshAct-inset1"
        @change="$emit('toggleCheckbox')"
      >
      <span class="elz d-block grow">{{ item.title }}</span>
    </label>
    <div class="elz d-flex a-X gap8 pL16 pV8 grow fb80">
      <label
        :class="{uDisabled: !isSelected}"
        class="elz d-grid w100p grow noShrink grPos fn fn-primary-t fnL20 fnLInvD fnHovL10 fnFow-focus fnFowL0"
      >
        <input
          :value="item.sum"
          type="number"
          min="0"
          placeholder="0"
          class="elz elzInput d-block grPin grY2 w100p bor1 r3 h24 pL8 fn12 ellipsis trns2 invPssSib bg bg-primary bgL10 bgLInvD br brLInvD br-primary brL-10 brHovL-20 brFoc-focus brFocL0 fn fn-primary-t"
          @change="(e) => $emit('updateSum', item.id, e.target.value)"
        >
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheckboxInputItem",

  props: {
    item:       { required: true, type: Object },
    isSelected: { required: true, type: Boolean}
  },

  emits: [ 'toggleCheckbox', 'updateSum' ]
}
</script>
