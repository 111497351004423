export default {

  methods: {
    showTooltip(name, el) {
      if (name.includes('password')) {
        el.type = 'text';
        setTimeout(() => el.type = 'password', 3500);
      }

      navigator.clipboard.writeText(el.value)
        .then(() => this.$refs[name].isOpen = true);
    }
  }

}
