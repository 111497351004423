<template>
  <div
    v-if="dataIsReady"
    :class="{sel: isOpened || isFinished}"
    class="elz d-block p-rel bor1 r3 bg bg-primary bgL5 br br-primary brL-10 brLInvD hideSelOut showSelOut"
  >
    <div
      class="elz d-flex gap8 a-H p16 opAct07 cur-pointer"
      @click="toggleWrapper"
    >
      <div class="elz d-flex f-wrap a-H grow gap8">
        <div class="elz d-block grow fb320 lh12">
          <div class="elz d-block bold">
            Единоразовые услуги
          </div>
        </div>
      </div>

      <div
        v-if="account"
        class="empLogPassWrap elz d-flex a-H"
      >
        <div class="elz p-rel d-block">
          <Tooltip
            ref="account"
            :type="'ok'"
            :has-tail="true"
            :duration="1500"
          >
            Лицевой счет скопирован
          </Tooltip>
          <InputBase
            :model-value="account"
            :input-type="'text'"
            :readonly="true"
            :icon="'info'"
            :class-input="'rL3 selNone cur-inh h32 w100p '"
            :class-label="'empLogPassInput grow mR-1 cur-pointer'"
            :title="'Лицевой счет'"
            @on:click="(e) => showTooltip('account', e.currentTarget.querySelector('input'))"
          />
        </div>
      </div>

      <template v-if="!isFinished">
        <div
          class="elz p-rel d-block noShrink p-rel mskBef s8 deg180 cFillBef bgBef-CC hideSelIn"
          style="--elzMsk: url('/style/icons/arrow1.svg');"
        />
        <div
          class="elz p-rel d-block noShrink p-rel mskBef s8 cFillBef bgBef-CC showSelIn"
          style="--elzMsk: url('/style/icons/arrow1.svg');"
        />
      </template>

      <div
        v-else
        class="elz d-flex a-X wmn120 hmn28 p8 r3 fn11 bold bg cur-help bg-success fn fn-success-t"
        title="Единоразовые услуги оказаны"
      >
        {{ isFinished && !hasSelected ? 'Не выбраны' : 'Проведены' }}
      </div>
    </div>

    <div
      :class="{uDisabled: isFinished}"
      class="elz d-block borT1 br br-primary brL-10 brLInvD fn12 showSelIn"
    >
      <div class="elz d-block showSelIn lh15 uStrip stripEven stripHover stripLD strip005 showSelIn1">
        <template
          v-for="item in services"
          :key="item.id"
        >
          <CheckboxInputItem
            :item="item"
            :is-selected="item.isSelected"
            @toggleCheckbox="toggleItem(item)"
            @updateSum="updateSum"
          />
        </template>
      </div>

      <div
        v-if="!isFinished"
        class="elz p-rel d-flex f-wrap a-X gap8 pV16 borT1 br br-primary brL-10 brLInvD"
      >
        <div
          :class="[dataSaveError ? ' bg-error fn-error-t' : 'cur-pointer opAct07 bgHovL10 bg-ok fn-ok-t',{uDisabled: !hasSelected}]"
          class="confirmAction elz d-flex gap8 a-X al-center r3 hmn36 pH24 bg fn"
          @click="checkClickOutState"
        >
          <div class="elz d-block">
            {{ dataSaveError ? 'Ошибка сохранения. Попробуйте еще раз.': confirmAction ? 'Уверен???' : 'Провести услуги' }}
          </div>
        </div>
      </div>
    </div>

    <template v-if="showUploader">
      <Uploader
        :circle-size="'s32'"
        :circle-width="'2'"
        :view-settings="'p-abs p16 r3 z10 bg bg-primary bgL5 br br-primary brL-10 brLInvD bgA50'"
      />
    </template>
  </div>
</template>

<script>
import Tooltip from "@/components/elements/Tooltip";
import InputBase from "@/components/elements/InputBase";
import CheckboxInputItem from "@/components/elements/CheckboxInputItem";
import showTooltip from "@/mixins/showTooltip";
import clickOut from "@/mixins/clickOut";
import { toRaw } from 'vue';
import { useStore } from "vuex";


export default {
  name: "OrderServicesOneTime",

  components: {
    Tooltip,
    InputBase,
    CheckboxInputItem
  },

  mixins: [ clickOut, showTooltip ],

  props: {
    dealId: { required: true, type: Number },
    customerSdId: { required: true, type: Number }
  },

  setup(props) {
    const store = useStore();

    store.dispatch('orderPage/fetchOneTimeServicesConnected', {
      dealId: props.dealId,
      servicesList: toRaw(store.state.static.oneTimeServices)
    });
  },

  data() {
    return {
      showUploader: false,
      confirmAction: false,
      dataSaveError: false,
      isOpened: false
    }
  },

  computed: {
    account() {
      return this.$store.state.orderPage.oneTimeServices.account;
    },

    dataIsReady() {
      return this.$store.state.orderPage.oneTimeServices.readyState;
    },

    hasSelected() {
      return this.services.filter(({costId}) => costId !== '').length > 0;
    },

    isFinished() {
      return this.$store.state.orderPage.oneTimeServices.connected.length > 0 || this.$store.state.orderPage.order.details.meetingStatusId >= 5;
    },

    services() {
      return this.$store.state.orderPage.oneTimeServices.list;
    },

    servicesList() {
      return this.$store.state.static.oneTimeServices;
    }
  },

  methods: {
    checkClickOutState() {
      if (!this.confirmAction) {
        this.confirmAction = true;
        this.setClickOut('confirmAction');
      } else {
        this.confirmAction = false;
        this.setServices();
      }
    },

    findNearest(value, costs) {
      const costsLen = costs.length;
      const searchIndex = Math.floor((costsLen - 1) / 2);
      const { id, cost } = costs[searchIndex];

      if (value === cost || (costsLen === 1 && (searchIndex <= 0 || searchIndex === costsLen - 1))) {
        return id;
      }

      const nextCost = costs[searchIndex + 1];

      if (cost < value && value < nextCost.cost) {
        const currentDif = value - cost;
        const nextDif = nextCost.cost - value;
        return (currentDif < nextDif) ? id : nextCost.id;
      }

      return this.findNearest(value, value < cost ? costs.slice(0, searchIndex + 1) : costs.slice(searchIndex + 1, costs.length));
    },

    async setServices() {
      this.showUploader = true;

      let selected = [];
      this.services.filter(({costId}) => costId !== '').forEach(({ costId, id }) => selected.push({costId: costId, serviceId: id}));

      const status = await this.$store.dispatch('orderPage/setOneTimeServices', {
        dealId: this.dealId,
        customerSdId: this.customerSdId,
        services: selected
      });

      this.$store.state.orderPage.oneTimeServices.connected = selected;

      if (status === 'ok') {
        this.services.filter(({isSelected, costId}) => isSelected === true && costId === '').forEach(el => el.isSelected = false);
      } else {
        this.dataSaveError = true;
        setTimeout(() => this.dataSaveError = false, 5000);
      }

      this.showUploader = false;
    },

    toggleItem(item) {
      item.isSelected = !item.isSelected;

      if (!item.isSelected) {
        item.costId = '';
        item.sum = 0;
      }
    },

    toggleWrapper(e) {
      if (e.target.tagName !== 'INPUT') {
        this.isOpened = !this.isOpened;
      }
    },

    updateSum(id, sum) {
      const service = this.servicesList.find(el => el.id === id);
      const costs = toRaw(service.costs);
      const result = this.findNearest(Math.abs(+sum), costs);

      service.costId = result;
      service.sum = 0;
      service.sum = costs.find(el => +el.id === +result).cost;
    }
  }
}
</script>


