<template>
  <div
    :class="{sel: service.isOpened}"
    class="elz d-block p-rel bor1 r3 mB16 bg bg-primary bgL5 br br-primary brL-10 brLInvD hideSelOut showSelOut"
  >
    <div
      :class="beforeTariffication ? 'cur-pointer opAct07' : ''"
      class="elz d-flex f-wrap gap8 a-H p16 hmn64"
      @click="beforeTariffication ? $emit('toggleServiceView') : ''"
    >
      <div class="elz d-flex f-wrap a-H grow gap8">
        <div class="elz d-block grow fb320 lh12">
          <div class="elz d-block bold">
            {{ service.name }}
          </div>
        </div>
        <div
          v-if="beforeTariffication"
          class="elz d-flex f-wrap fn12 gapH16 pR8 gapV8"
        >
          <div
            class="elz d-flex a-H gap8 al-right cur-help"
            :title="`Сумма к списанию: ${total} руб.`"
          >
            <div
              class="elz p-rel d-block p-rel mskBef s12 cFillBef bgBef-CC msk-contain"
              style="--elzMsk: url('/style/icons/ruble.svg');"
            />
            <div class="elz d-block bold">
              {{ total }}
            </div>
          </div>
        </div>
      </div>

      <template v-if="beforeTariffication">
        <div
          class="elz p-rel d-block noShrink p-rel mskBef s8 deg180 cFillBef bgBef-CC hideSelIn"
          style="--elzMsk: url('/style/icons/arrow1.svg');"
        />
        <div
          class="elz p-rel d-block noShrink p-rel mskBef s8 cFillBef bgBef-CC showSelIn"
          style="--elzMsk: url('/style/icons/arrow1.svg');"
        />
      </template>

      <template v-else-if="service.billingStart">
        <div class="empLogPassWrap elz d-flex a-H">
          <div class="empLogPassItem elz p-rel d-flex a-X fb200">
            <Tooltip
              ref="account"
              :type="'ok'"
              :has-tail="true"
              :duration="1500"
            >
              Лицевой счет скопирован
            </Tooltip>
            <InputBase
              :model-value="service.connectionData.personalAccountNumber || null"
              :input-type="'text'"
              :readonly="true"
              :icon="'info'"
              :class-input="'rL3 selNone cur-inh h32 w100p '"
              :class-label="'empLogPassInput grow mR-1 cur-pointer'"
              :title="'Лицевой счет'"
              @on:click="(e) => showTooltip('account', e.currentTarget.querySelector('input'))"
            />
          </div>
          <div class="empLogPassItem elz p-rel d-flex a-X fb200">
            <Tooltip
              ref="login"
              :type="'ok'"
              :has-tail="true"
              :duration="1500"
            >
              Логин скопирован
            </Tooltip>
            <InputBase
              :model-value="service.connectionData.login || null"
              :input-type="'text'"
              :readonly="true"
              :icon="'user'"
              :class-input="'rL3 selNone cur-inh h32 w100p '"
              :class-label="'empLogPassInput grow mR-1 cur-pointer'"
              :title="'Логин для управления услугой'"
              @on:click="(e) => showTooltip('login', e.currentTarget.querySelector('input'))"
            />
          </div>
          <div class="empLogPassItem elz p-rel d-flex a-X fb200">
            <Tooltip
              ref="password"
              :type="'ok'"
              :has-tail="true"
              :duration="1500"
            >
              Пароль скопирован
            </Tooltip>
            <InputBase
              :model-value="service.connectionData.pass || null"
              :input-type="'password'"
              :readonly="true"
              :icon="'key'"
              :class-input="'rR3 selNone cur-inh h32 w100p '"
              :class-label="'empLogPassInput grow cur-pointer'"
              :title="'Пароль для управления услугой'"
              @on:click="(e) => showTooltip('password', e.currentTarget.querySelector('input'))"
            />
          </div>
        </div>
        <div
          class="elz d-flex a-X wmn120 hmn28 p8 r3 fn11 bold bg cur-help bg-success fn fn-success-t"
          title="Услуга подключена"
        >
          На тарификации
        </div>
      </template>

      <template v-else>
        <div
          class="elz d-flex a-X wmn120 hmn28 p8 r3 fn11 bold bg cur-pointer opAct07 bg-success fn fn-success-t"
          @click="$emit('createConnection')"
        >
          Настроить тарификацию
        </div>
      </template>
    </div>

    <div
      :class="{uDisabled: service.billingStart}"
      class="elz d-block borT1 br br-primary brL-10 brLInvD fn12 showSelIn"
    >
      <div class="elz d-flex a-H f-wrap fn16 p16 gap8">
        <div class="elz d-block fb320 fn fn14">
          Тип услуги:
        </div>
        <div class="elz d-flex f-wrap gap8 fb480 grow">
          <div class="elz d-block fb320 growMax">
            <SelectBase
              v-model="serviceType"
              :disabled="service.billingStart"
              :options="types"
              :title="service.billingStart ? service.connectionData.type_name : 'Выбрать тип услуги'"
              @update:modelValue="(val) => { $emit('changeType', val); selectedTariff = '' }"
            />
          </div>
        </div>
      </div>
      <div
        v-if="serviceType || service.billingStart"
        class="elz d-flex a-H f-wrap fn16 pAT16 pB16 gap8"
      >
        <div class="elz d-block fb320 fn fn14">
          Тарифный план:
        </div>
        <template v-if="serviceType || service.billingStart">
          <div
            v-if="service.tariffList.length || service.billingStart"
            class="elz d-flex f-wrap gap8 fb480 grow"
          >
            <div class="elz d-block fb320 growMax">
              <SelectBase
                v-model="selectedTariff"
                :disabled="service.billingStart"
                :options="service.tariffList"
                :title="service.billingStart ? service.connectionData.name : 'Выбрать тарифный план'"
                @update:modelValue="(val) => $emit('changeTariff', val )"
              />
            </div>
            <div class="elz d-block fb120 grow wmn160">
              <div
                class="elz d-flex a-X bor1 r3 h40 pH16 bold al-center bg bg-primary bgL10 bgLInvD br brLInvD br-primary brL-10"
              >
                {{ tariffPrice }}
              </div>
            </div>
          </div>
          <div
            v-else
            class="elz d-flex a-H f-wrap gap8 fb480 grow pH16 r3 lh12 hmn40 bg bg-primary bgLInvD"
          >
            Тарифный план не найден. Обратитесь ДИТ Управление эксплуатации АСР.
          </div>
        </template>
      </div>
      <div
        v-if="selectedTariff && !service.billingStart"
        class="elz p-rel d-flex f-wrap a-X gap8 pV16 borT1 br br-primary brL-10 brLInvD"
      >
        <ButtonBase
          :class-list="'confirmAction hmn36 bg-ok bgHovL10 fn-ok-t'"
          @onButtonClick="checkButtonState"
        >
          {{ confirmAction ? 'Уверен???' : 'Поставить на тарификацию' }}
        </ButtonBase>
      </div>
    </div>

    <template v-if="service.showUploader">
      <Uploader
        :circle-size="'s32'"
        :circle-width="'2'"
        :view-settings="'p-abs p16 r3 z10 bg bg-primary bgL5 br br-primary brL-10 brLInvD bgA50'"
      />
    </template>
  </div>
</template>

<script>
import ButtonBase from '@/components/elements/ButtonBase';
import InputBase from '@/components/elements/InputBase';
import SelectBase from '@/components/elements/SelectBase';
import Tooltip from '@/components/elements/Tooltip';
import clickOut from '@/mixins/clickOut';
import showTooltip from '@/mixins/showTooltip';
import { numberFormat } from '@/helpers/formating';


export default {
  name: 'OrderServicesHydra',

  components: {
    ButtonBase,
    InputBase,
    SelectBase,
    Tooltip,
  },

  mixins: [ clickOut, showTooltip ],

  props: {
    service: { required: true, type: Object },
  },

  emits: [ 'createConnection', 'changeTariff', 'changeType', 'setTariffication', 'toggleServiceView' ],

  data() {
    return {
      confirmAction: false,
      serviceType: '',
      selectedTariff: '',
    };
  },

  computed: {
    beforeTariffication() {
      return this.service.isConnected && !this.service.billingStart;
    },

    tariffPrice() {
      return this.service.billingStart ? numberFormat(+this.service.connectionData.amount, 2, ',', ' ') : numberFormat(this.service.tariffList.find(el => +el.value === +this.selectedTariff)?.price, 2, ',', ' ');
    },

    total() {
      return numberFormat(+(this.service.tariffList.find(el => +el.value === +this.selectedTariff)?.price), 2, ',', ' ');
    },

    types() {
      return this.$store.state.static.hydraServicesTypes.find(({ typeOfService }) => +typeOfService === +this.service.typeOfService).list;
    },
  },

  methods: {
    checkButtonState() {
      if ( !this.confirmAction ) {
        this.confirmAction = true;
        this.setClickOut('confirmAction');
      } else {
        this.$emit('setTariffication', this.selectedTariff, this.service.baseContractHydraId);
        this.confirmAction = false;
      }
    },
  },
};
</script>


<style>
@media (max-width: 720px) {
  .empLogPassWrap {
    flex-basis: 100%;
  }
}

@media (max-width: 480px) {
  .empLogPassWrap {
    flex-wrap: wrap;
  }

  .empLogPassItem {
    flex-basis: 100%;
  }

  .empLogPassItem:not(:last-child) .d-grid {
    margin-right: 0;
    margin-bottom: -1px;
  }

  .empLogPassItem:first-child .elzInput {
    border-radius: 3px 3px 0 0;
  }

  .empLogPassItem:last-child .elzInput {
    border-radius: 0 0 3px 3px;
  }

  .empLogPassItem:not(:last-child):not(:first-child) .elzInput {
    border-radius: 0;
  }

}
</style>