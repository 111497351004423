<template>
  <template v-if="isActive">
    <div class="elz d-flex a-X mB32">
      <a
        :href="`http://tts.naukanet.ru/customers/orderinfo.asp?OrderID=${$store.state.orderPage.order.details.OrderID}`"
        target="_blank"
        :class="[{ uDisabled: !isDisabled },'h48 bg-ok fn-ok-t']"
        :title="buttonTitle"
        class="elz d-flex gap8 a-X r3 pH32 cur-pointer opAct07 bg fn"
      >
        <div class="elz d-block grow al-center">{{ buttonTitle }}</div>
      </a>
    </div>

    <div class="elz d-flex gapH16 gapV32 f-wrap">
      <FileUploadWrap
        :name="'act'"
        :title="'Загрузить акт'"
        :file-list="$store.state.orderPage.files.act"
        :icon-name="'rules'"
        :sub-title="'Нажмите чтобы загрузить файл'"
        @uploadFile="uploadFile"
      />

      <FileUploadWrap
        :name="'blanc'"
        :title="'Загрузить бланк заказа'"
        :file-list="$store.state.orderPage.files.blanc"
        :icon-name="'file-presentation'"
        :sub-title="'Нажмите чтобы загрузить файл'"
        @uploadFile="uploadFile"
      />

      <FileUploadWrap
        :name="'doc'"
        :title="'Загрузить файл'"
        :file-list="$store.state.orderPage.files.doc"
        :icon-name="'file-presentation'"
        :sub-title="'Нажмите чтобы загрузить файл'"
        @uploadFile="uploadFile"
      />
    </div>
  </template>
</template>

<script>
import FileUploadWrap from "@/components/elements/FileUploadWrap";

export default {
  name: "OrderFinish",

  components: {
    FileUploadWrap
  },

  data() {
    return {
      isActive: false
    }
  },

  computed: {
    buttonTitle(){
      return this.isDisabled ? 'Укажите технические услуги в заявке в ТТС' : 'Требуется загрузить сканы документов';
    },

    isDisabled() {
      return this.$store.state.orderPage.files.act.length && this.$store.state.orderPage.files.blanc.length;
    },

    orderDetails() {
      return this.$store.state.orderPage.order.details;
    }
  },

  created() {
    this.$store.dispatch('orderPage/fetchDownloadedFiles', this.orderDetails.OrderID);
  },

  methods: {
    uploadFile(file) {
      file.customerId = this.orderDetails.CustomerIDSD;
      file.dealId = this.orderDetails.DealID;
      file.orderId = this.orderDetails.OrderID;

      this.$store.dispatch('orderPage/uploadFileToServer', file);
    }
  }
}
</script>

