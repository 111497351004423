<template>
  <div
    class="elz d-flex gap8 a-X r3 pH32 cur-pointer opAct07 bg fn"
    :class="classList"
    @click="$emit('onButtonClick')"
  >
    <div
      v-if="iconName"
      class="elz p-rel d-block p-rel noShrink mskBef s16 cFillBef bgBef-CC"
      :style="`--elzMsk: url('/style/icons/${iconName}.svg');`"
    />
    <div class="elz d-block grow al-center">
      {{ title }}

      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonBase",

  props: {
    classList: { required: true,  type: [Array, String], default:'' },
    iconName:  { required: false, type: String, default:'' },
    title:     { required: false, type: String, default:'' }
  },

  emits: [ 'onButtonClick' ]
}
</script>

<style scoped>

</style>
