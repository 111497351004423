<template>
  <template v-if="isActive && dataIsReady">
    <div
        :class="{uDisabled: connectionFinished}"
        class="elz d-block cnnClientWrap"
        @input="defineSendButtonState"
    >
      <div class="elz cnnInfoCaption d-block bold pV8 mB16 fn18 borB1 br br-primary brL-10 brLInvD">
        Паспортные данные
      </div>

      <div class="elz cnnInfoGrid d-block mL-24">
        <InputItem
            ref="passTypeId"
            :autocomplete="passTypeInputFocused ? passTypes : null"
            :model-value="passTypeText"
            input-type="text"
            placeholder="Паспорт гражданина РФ"
            title-main="Документ"
            title-focus="Документ"
            :input-props="validationPassType(customer.passTypeId)"
            @autocompleteSelected="savePassType"
            @focus="changePassTypeInputFocus(true)"
            @blur="changePassTypeInputFocus(false)"
        />

        <InputItem
            ref="surname"
            :autocomplete="false"
            :model-value="customer.surname"
            :input-type="'text'"
            :input-mask="isResident ? validation.word : null"
            :placeholder="'Иванов'"
            :title-main="'Фамилия:'"
            :title-focus="'Фамилия:'"
            :input-props="validationText(customer.surname, 'surname')"
            @updateModelValue="(val) => customer.surname = val"
        />

        <InputItem
            ref="name"
            :model-value="customer.name"
            :input-type="'text'"
            :input-mask="isResident ? validation.word: null"
            :placeholder="'Иван'"
            :title-main="'Имя:'"
            :title-focus="'Имя:'"
            :input-props="validationText(customer.name, 'name')"
            @updateModelValue="(val) => customer.name = val"
        />

        <InputItem
            :model-value="customer.patronymic"
            :input-type="'text'"
            :placeholder="'Иванович'"
            :title-main="'Отчество:'"
            :title-focus="'Отчество:'"
            :input-props="customer.patronymic ? elemProps.success : ''"
            @updateModelValue="(val) => customer.patronymic = val"
        />

        <InputItem
            ref="birthdayDate"
            :model-value="customer.birthdayDate"
            :input-type="'text'"
            :input-mask="'##.##.####'"
            :placeholder="'ДД.ММ.ГГГГ'"
            :title-main="'Дата рождения:'"
            :title-focus="'Дата рождения:'"
            :input-props="validationDate(customer.birthdayDate, 'birthdayDate')"
            @updateModelValue="(val) => customer.birthdayDate = val"
        />

        <InputItem
            v-if="!isResident"
            ref="passCountry"
            :model-value="customer.passCountry"
            input-type="text"
            placeholder="Российская Федерация"
            title-main="Страна:"
            title-focus="Страна:"
            :input-props="validationText(customer.passCountry, 'passCountry')"
            @updateModelValue="(val) => customer.passCountry = val"
        />

        <InputItem
            v-if="!isResident"
            ref="passNationality"
            :model-value="customer.passNationality"
            input-type="text"
            placeholder="Эфиопия"
            title-main="Гражданство:"
            title-focus="Гражданство:"
            :input-props="validationText(customer.passNationality, 'passNationality')"
            @updateModelValue="(val) => customer.passNationality = val"
        />

        <InputItem
            v-if="!isResident"
            ref="passSex"
            :model-value="customer.passSex"
            input-type="text"
            placeholder="мужской"
            title-main="Пол:"
            title-focus="Пол:"
            :input-props="validationText(customer.passSex, 'passSex')"
            @updateModelValue="(val) => customer.passSex = val"
        />

        <InputItem
            ref="birthdayPlace"
            :model-value="customer.birthdayPlace"
            :input-type="'text'"
            :input-mask="validation.text"
            :placeholder="'г. Москва'"
            :title-main="'Место рождения:'"
            :title-focus="'Место рождения:'"
            :input-props="validationText(customer.birthdayPlace, 'birthdayPlace', 4)"
            @updateModelValue="(val) => customer.birthdayPlace = val"
        />

        <div class="elz infoLine d-block mB32">
          <div class="elz infoTitle d-flex a-H hmn40 borB2 mL24 noShrink">
            <div class="elz d-block">
              Паспорт серия:
            </div>
          </div>
          <div class="elz d-flex cnnFlexibleInputs">
            <InputItem
                v-if="isResident"
                ref="series"
                :model-value="customer.passportSeries"
                :input-type="'text'"
                :input-mask="'## ##'"
                :placeholder="'1234'"
                :title-focus="'Серия:'"
                :input-props="validationText(customer.passportSeries, 'passportSeries', 5)"
                @updateModelValue="(val) => customer.passportSeries = val"
            />
            <InputItem
                v-else
                ref="series"
                :model-value="customer.passportSeries"
                input-type="text"
                placeholder="1234"
                title-focus="Серия:"
                :input-props="validationText(customer.passportSeries, 'passportSeries')"
                @updateModelValue="(val) => customer.passportSeries = val"
            />

            <div class="elz infoTitle d-flex a-H hmn40 borB2 mL24 noShrink">
              <div class="elz d-block">
                Номер:
              </div>
            </div>
            <InputItem
                v-if="isResident"
                ref="number"
                :model-value="customer.passportNumber"
                :input-type="'text'"
                :input-mask="'### ###'"
                :placeholder="'567890'"
                :title-focus="'Номер:'"
                :input-props="validationText(customer.passportNumber, 'passportNumber', 7)"
                @updateModelValue="(val) => customer.passportNumber = val"
            />
            <InputItem
                v-else
                ref="number"
                :model-value="customer.passportNumber"
                input-type="text"
                placeholder="567890"
                title-focus="Номер:"
                :input-props="validationText(customer.passportNumber, 'passportNumber')"
                @updateModelValue="(val) => customer.passportNumber = val"
            />
          </div>
        </div>

        <InputItem
            ref="issueDate"
            :model-value="customer.issueDate"
            :input-type="'text'"
            :input-mask="'##.##.####'"
            :placeholder="'ДД.ММ.ГГГГ'"
            :title-main="'Дата выдачи:'"
            :title-focus="'Дата выдачи:'"
            :input-props="validationDate(customer.issueDate, 'issueDate')"
            @updateModelValue="(val) => customer.issueDate = val"
        />

        <InputItem
            v-if="isResident"
            ref="ufmsCode"
            :model-value="customer.ufmsCode"
            :input-type="'text'"
            :input-mask="'###-###'"
            :placeholder="'123-456'"
            :title-main="'Код подразделения:'"
            :title-focus="'Код подразделения:'"
            :input-props="validationText(customer.ufmsCode, 'ufmsCode', 7)"
            @updateModelValue="(val) => {customer.ufmsCode = val; validationDaDataUfmsCode();}"
        />

        <InputItem
            v-else
            ref="ufmsCode"
            :model-value="customer.ufmsCode"
            input-type="text"
            placeholder="123-456"
            title-main="Код подразделения:"
            title-focus="Код подразделения:"
            :input-props="validationText(customer.ufmsCode, 'ufmsCode')"
            @updateModelValue="(val) => {customer.ufmsCode = val; validationDaDataUfmsCode();}"
        />

        <InputItem
            ref="issueDepartment"
            :autocomplete="ufmsList.length ? ufmsList : false"
            :model-value="customer.issueDepartment"
            :input-type="'text'"
            :input-mask="validation.text"
            :placeholder="'Московским РОВД Южного округа'"
            :title-main="'Кем выдан:'"
            :title-focus="'Кем выдан:'"
            :input-props="validationText(customer.issueDepartment, 'issueDepartment', 4)"
            @updateModelValue="(val) => customer.issueDepartment = val"
            @autocompleteSelected="updateIssueDepartment"
        />

        <template v-if="isResident">
          <InputItem
              ref="registrationAddress"
              :autocomplete="addressRegisterList.length ? addressRegisterList : false"
              :model-value="customer.registrationAddress"
              :input-type="'text'"
              :input-mask="validation.text"
              :placeholder="'Москва, ул. 2я Хуторская д. 38А стр. 9'"
              :title-main="'Адрес прописки:'"
              :title-focus="'Город, улица, дом'"
              :input-props="validationText(customer.registrationAddress, 'registrationAddress', 5)"
              @updateModelValue="(val) => {customer.registrationAddress = val; validationDaDataAddress('registrationAddress', 'addressRegisterList');}"
              @autocompleteSelected="(idx) => updateAddress(idx, 'registrationAddress', 'addressRegisterList')"
          />

          <div class="elz infoLine d-block mB32">
            <div class="elz infoTitle d-flex a-H hmn40 borB2 mL24 noShrink"/>
            <div class="elz d-flex cnnFlexibleInputs">
              <InputItem
                  :model-value="customer.registrationAddressEntrance"
                  :input-type="'text'"
                  :title-focus="'Подъезд:'"
                  :input-props="customer.registrationAddressEntrance ? elemProps.success : ''"
                  @updateModelValue="(val) => customer.registrationAddressEntrance = val"
              />

              <InputItem
                  :model-value="customer.registrationAddressLevel"
                  :input-type="'text'"
                  :title-focus="'Этаж:'"
                  :input-props="customer.registrationAddressLevel ? elemProps.success : ''"
                  @updateModelValue="(val) => customer.registrationAddressLevel = val"
              />

              <InputItem
                  :model-value="customer.registrationAddressFlat"
                  :input-type="'text'"
                  :title-focus="'Квартира'"
                  :input-props="customer.registrationAddressFlat ? elemProps.success : ''"
                  @updateModelValue="(val) => customer.registrationAddressFlat = val"
              />
            </div>
          </div>
        </template>
        <InputItem
            v-else
            ref="passUntypedAddress"
            :model-value="customer.passUntypedAddress"
            input-type="text"
            placeholder="Москва, ул. 2я Хуторская д. 38А стр. 9"
            title-main="Адрес прописки:"
            title-focus="Адрес прописки:"
            :input-props="validationText(customer.passUntypedAddress, 'passUntypedAddress')"
            @updateModelValue="(val) => customer.passUntypedAddress = val"
        />

        <InputItem
            ref="factAddress"
            :autocomplete="addressFactList.length ? addressFactList : false"
            :model-value="customer.factAddress"
            :input-type="'text'"
            :input-mask="validation.text"
            :placeholder="'Москва, ул. 2я Хуторская д. 38А стр. 9'"
            :title-main="'Фактический адрес:'"
            :title-focus="'Город, улица, дом'"
            :input-props="validationText(customer.factAddress, 'factAddress', 5)"
            @updateModelValue="(val) => {customer.factAddress = val; validationDaDataAddress('factAddress', 'addressFactList');}"
            @autocompleteSelected="(idx) => updateAddress(idx, 'factAddress','addressFactList')"
        />

        <div class="elz infoLine d-block mB32">
          <div class="elz infoTitle d-flex a-H hmn40 borB2 mL24 noShrink"/>
          <div class="elz d-flex cnnFlexibleInputs">
            <InputItem
                :model-value="customer.factEntrance"
                :input-type="'text'"
                :title-focus="'Подъезд:'"
                :force-show-title-focus="true"
                :input-props="customer.factEntrance ? elemProps.success : ''"
                @updateModelValue="(val) => customer.factEntrance = val"
            />

            <InputItem
                :model-value="customer.factLevel"
                :input-type="'text'"
                :title-focus="'Этаж:'"
                :force-show-title-focus="true"
                :input-props="customer.factLevel ? elemProps.success : ''"
                @updateModelValue="(val) => customer.factLevel = val"
            />

            <InputItem
                :model-value="customer.factFlat"
                :input-type="'text'"
                :title-focus="'Квартира'"
                :force-show-title-focus="true"
                :input-props="customer.factFlat ? elemProps.success : ''"
                @updateModelValue="(val) => customer.factFlat = val"
            />
          </div>
        </div>
      </div>
    </div>

    <div
        :class="{uDisabled: connectionFinished}"
        class="elz d-block mT48 cnnClientWrap"
        @input="defineSendButtonState"
    >
      <div class="elz cnnInfoCaption d-block bold pV8 mB16 fn18 borB1 br br-primary brL-10 brLInvD">
        Контактные данные
      </div>
      <div class="elz cnnInfoGrid d-block mL-24">
        <InputItem
            ref="mobile"
            :model-value="customer.mobile"
            :input-type="'text'"
            :input-mask="'+7 (###) ###-##-##'"
            :placeholder="'+7 (901) 12-34-567'"
            :title-main="'Мобильный телефон:'"
            :title-focus="'Мобильный телефон:'"
            :input-props="validationText(customer.mobile, 'mobile', 18)"
            @updateModelValue="(val) => customer.mobile = val"
        />

        <InputItem
            ref="email"
            :model-value="customer.email ? customer.email.trim() : customer.email"
            :input-type="'text'"
            :input-mask="validation.email"
            :placeholder="'example@yandex.ru'"
            :title-main="'E-Mail:'"
            :title-focus="'E-Mail:'"
            :input-props="validationEmail()"
            @updateModelValue="(val) => customer.email = val"
        />
      </div>
    </div>

    <div class="elz d-flex dir-y a-X mT48">
      <template v-if="customerUpdateStatus !== 0 && customerUpdateStatus !== 2 && !connectionFinished">
        <ButtonBase
            :class-list="buttonClass"
            :title="buttonTitle"
            @onButtonClick="updateCustomerHydraState"
        />
      </template>

      <div
          v-if="customerUpdateError"
          class="elz d-block al-center r3 fn12 p16 lh15 mT24 bg bg-error fn fn-error-t"
      >
        <div class="elz d-block">
          <span class="bold">Ошибка сохранения</span>: {{ customerUpdateError.code }}
        </div>
        <div
            v-if="customerUpdateError.message"
            class="elz d-block pT6"
        >
          {{ customerUpdateError.message }}
        </div>
      </div>
    </div>
  </template>

  <template v-if="showUploader">
    <Uploader
        :circle-size="'s100'"
        :circle-width="'2'"
        :view-settings="'p-abs p16 r3 z10 bg bg-primary bgL5 br br-primary brL-10 brLInvD bgA50'"
        :title="uploaderTitle"
    />
  </template>
</template>

<script>
import InputItem from '@/components/elements/InputItem';
import ButtonBase from '@/components/elements/ButtonBase';
import DaDataService from '@/services/DaDataService';
import { inputOptions, inputValidation } from '@/helpers/elements_common';
import { useStore } from 'vuex';
import { readonly, ref, computed } from 'vue';


export default {
  name: 'OrderCustomer',

  components: {
    InputItem,
    ButtonBase,
  },

  setup() {
    const store = useStore();

    const dealId = store.state.orderPage.order.details.DealID;

    store.dispatch('orderPage/fetchCustomerInfo', {
      customerId: store.state.orderPage.order.details.CustomerIDTTS,
      dealId: dealId,
    });

    const passTypeInputFocused = ref(false);

    const passTypes = computed(() => store.getters['orderPage/passTypes']);

    const passTypeId = computed(() => store.getters['orderPage/customerPassId']);

    const passTypeText = computed(() => store.getters['orderPage/customerPassText']);


    return {
      dealId,
      elemProps: readonly(inputOptions()),
      validation: readonly(inputValidation()),
      passTypeInputFocused,
      passTypes,
      passTypeId,
      passTypeText,
    };
  },

  data() {
    return {
      dataSaved: false,
      formIsValid: false,
      isActive: false,
      showUploader: true,

      addressRegisterList: [],
      addressFactList: [],
      ufmsList: [],

      customerUpdateStatus: undefined,
      customerUpdateError: undefined,
      customerUpdateUBN: null,

      buttonTitle: 'Сохранить данные',
      validationForm: {},
    };
  },

  computed: {
    buttonClass() {
      return this.dataSaved ? 'hmn48 bg-success bgHovL10 fn-success-t uDisabled' : `hmn48 bg-ok bgHovL10 fn-ok-t ${ this.formIsValid || this.isValid ? '' : 'uDisabled' }`;
    },

    connectionFinished() {
      return this.$store.state.orderPage.order.details.meetingStatusId > 4;
    },

    customer() {
      return this.$store.state.orderPage.customerInfo;

    },

    isResident() {
      return this.customer?.isResident == 1 || false;
    },

    dataIsReady() {
      return this.$store.state.orderPage.customerInfo.readyState;
    },

    uploaderTitle() {
      return this.customerUpdateStatus === 0 || this.customerUpdateStatus === null ? 'Данные сохраняются</br>Дождитесь завершения' : '';
    },
    isValid() {
      const requiredFields = this.isResident ? [
        'surname',
        'name',
        'birthdayDate',
        'birthdayPlace',
        'passportSeries',
        'passportNumber',
        'issueDate',
        'ufmsCode',
        'issueDepartment',
        'registrationAddress',
        'mobile',
        'email',
        'passTypeId',
      ] : [
        'surname',
        'name',
        'passportSeries',
        'mobile',
        'email',
        'passTypeId',
        'factAddress',
      ];

      let valid = true;

      requiredFields.forEach(fieldName => {
        if ( !this.validationForm[fieldName] ) {
          valid = false;
        }
      });

      return valid;
    },


  },

  watch: {
    customer: {
      deep: true,
      handler: function() {
        this.dataSaved = false;
      },
    },

    customerUpdateStatus() {
      if ( this.customerUpdateStatus !== undefined ) {
        console.log('watcher triggered');
        this.updateCustomerInfo();
      }
    },

    dataIsReady() {
      console.log('isReady', this.customer);
      this.showUploader = !this.dataIsReady && this.isActive;
    },

  },

  methods: {

    defineSendButtonState() {
      if ( this.isResident ) {
        this.formIsValid = (this.$refs.surname.$el.classList.contains('isValidValue')
            && this.$refs.name.$el.classList.contains('isValidValue')
            && this.$refs.birthdayDate.$el.classList.contains('isValidValue')
            && this.$refs.birthdayPlace.$el.classList.contains('isValidValue')
            && this.$refs.series.$el.classList.contains('isValidValue')
            && this.$refs.number.$el.classList.contains('isValidValue')
            && this.$refs.issueDate.$el.classList.contains('isValidValue')
            && this.$refs.ufmsCode.$el.classList.contains('isValidValue')
            && this.$refs.issueDepartment.$el.classList.contains('isValidValue')
            && this.$refs.registrationAddress.$el.classList.contains('isValidValue')
            && this.$refs.mobile.$el.classList.contains('isValidValue')
            && this.$refs.email.$el.classList.contains('isValidValue')
            && this.$refs.passTypeId.$el.classList.contains('isValidValue')
        );
      } else {
        this.formIsValid = (
            this.$refs.surname.$el.classList.contains('isValidValue')
            && this.$refs.name.$el.classList.contains('isValidValue')
            && (this.$refs.series.$el.classList.contains('isValidValue') || this.$refs.number.$el.classList.contains('isValidValue'))
            && this.$refs.mobile.$el.classList.contains('isValidValue')
            && this.$refs.email.$el.classList.contains('isValidValue')
            && this.$refs.passTypeId.$el.classList.contains('isValidValue')
            && this.$refs.factAddress.$el.classList.contains('isValidValue')
            // && this.$refs.registrationAddress.$el.classList.contains('isValidValue')
        );

      }
    },

    async updateCustomerInfo() {
      this.showUploader = true;

      // if ( this.customerUpdateStatus === 1 ) {
      console.log('КЛИЕНТ ЗАВЕДЕН, ПОЛУЧЕН UBN');
      this.$store.state.orderPage.order.details.CustomerUBN = this.customerUpdateUBN;

      if ( this.customer.registrationAddressFiass === undefined ) {
        this.customer.registrationAddressFiass = 'old value';
      }

      if ( this.customer.factAddressFiass === undefined ) {
        this.customer.factAddressFiass = 'old value';
      }

      this.customer.passportDetails = this.isResident ? `${ this.customer.passportSeries?.replace(/ +/g, '') } ${ this.customer.passportNumber.replace(/ +/g, '') }` : null;
      const data = this.customer;
      data.dealId = this.dealId;

      data.isResident = this.isResident ? 1 : 0;

      console.log('updateCustomerInfo data', data);

      await this.$store.dispatch('orderPage/updateCustomerInfo', data);

      // this.buttonTitle = 'Данные сохранены';

      // setTimeout(() => this.dataSaved = true, 100);

      // this.showUploader = false;
      // } else if ( this.customerUpdateStatus === 2 ) {
      if ( this.customerUpdateStatus === 2 ) {
        console.log('ВОЗНИКЛА ОШИБКА СОХРАНЕНИЯ');
        this.showUploader = false;
      } else if ( this.customerUpdateStatus === 3 ) {
        console.log('ЗАПРОС ПРОТУХ');
        this.buttonTitle = 'ЧТО-ТО ПОШЛО НЕ ТАК! ПОПРОБУЙТЕ ЕЩЕ РАЗ';
        this.showUploader = false;
      } else if ( this.customerUpdateStatus === 0 ) {
        console.log('ПОВТОРНЫЙ ЗАПРОС');
        this.buttonTitle = 'Данные сохраняются';
        setTimeout(() => {
          this.updateCustomerHydraState();
          this.buttonTitle = 'Данные сохранены';
          this.dataSaved = true;
          this.showUploader = false;
        }, 10000);
        // await this.updateCustomerHydraState();
      } else {
        this.buttonTitle = 'Данные сохранены';
        this.dataSaved = true;
        this.showUploader = false;
      }

    },

    async updateCustomerHydraState() {
      this.showUploader = true;
      this.customerUpdateStatus = undefined;
      let response = await this.$store.dispatch('orderPage/updateCustomerUBN', this.customer.customerIdSD);

      // console.log('response', response);

      this.customerUpdateStatus = response.status;
      this.customerUpdateUBN = response.ubn;

      if ( response.errorCode || response.errorMessage ) {
        this.customerUpdateError = {
          code: response.errorCode || '0',
          message: response.errorMessage || '',
        };
      } else {
        this.customerUpdateError = undefined;
      }
      this.showUploader = false;
    },

    updateAddress(index, paramName, listName) {
      const { house_type, house, block_type, block } = this[listName][index].data;
      let houseName = house_type + ' ' + house;
      if ( block ) {
        houseName += ' ' + block_type + ' ' + block;
      }

      this.customer[`${ paramName }Fiass`] = this[listName][index].data;
      this.customer[`${ paramName }Fiass`].houseName = houseName;
      this.customer[`${ paramName }Fiass`].fullAddress = this[listName][index].value;
      this.customer[paramName] = this[listName][index].value;
      this[listName] = [];
      setTimeout(() => this.defineSendButtonState(), 500);
    },

    updateIssueDepartment(val) {

      if ( this.isResident ) {
        this.customer.issueDepartment = this.ufmsList[val].value;
        this.ufmsList = [];
      }
      setTimeout(() => this.defineSendButtonState(), 500);
    },

    async validationDaDataAddress(paramName, listName) {
      if ( this.customer[paramName].length >= 4 ) {
        this[listName] = (await DaDataService.getSuggestionAddress(this.customer[paramName])).suggestions;
      }
      if ( !this[listName].length ) {
        this.customer[`${ paramName }Fiass`] = 'is not exist';
      }
    },

    async validationDaDataUfmsCode() {
      if ( this.isResident ) {
        if ( this.customer.ufmsCode.length === 7 && this.customer.issueDepartment !== '' ) {
          return;
        }
        if ( this.customer.ufmsCode.length < 7 ) {
          this.customer.issueDepartment = '';
          return;
        }
        if ( this.customer.ufmsCode.length === 7 ) {
          this.ufmsList = (await DaDataService.getFmsUnits(this.customer.ufmsCode)).suggestions;  //  '660-042' , '772-050'
          this.customer.issueDepartment = '';
        }

      }
    },

    validationDate(data, fieldName) {
      let isValid = false;

      if ( data ) {
        const dateArr = data.split('.');
        const [ dd, mm, yyyy ] = dateArr;
        if ( +yyyy > 1901 ) {
          const date = new Date(`${ yyyy }-${ mm }-${ dd }T00:00:00`);
          isValid = !isNaN(date.getTime());
        }
      }

      this.validate(fieldName, isValid);

      return isValid ? this.elemProps.success : this.elemProps.error;

    },

    validationEmail() {
      if ( this.customer.email ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        let data = this.customer.email.replace(/ +/g, '').trim();

        const isValid = !!data.match(pattern);

        this.validate('email', isValid);

        return isValid ? this.elemProps.success : this.elemProps.error;

      }
    },

    validationText(data, fieldName, size = 2) {
      const isValid = !!data && data.length >= size;

      this.validate(fieldName, isValid);

      return isValid ? this.elemProps.success : this.elemProps.error;

    },

    validationPassType(passTypeId) {
      const passTypeValid = this.passTypes.find(pass => pass.id === passTypeId) || false;

      this.validate('passTypeId', !!passTypeValid);

      return passTypeValid ? this.elemProps.success : this.elemProps.error;
    },

    validate(fieldName, isValid) {
      this.validationForm[fieldName] = isValid;
    },

    savePassType(passTypeIndex) {
      this.customer.passTypeId = this.passTypes[passTypeIndex].id;
      this.changePassTypeInputFocus(false);
    },

    changePassTypeInputFocus(isFocused) {
      this.passTypeInputFocused = isFocused;
    },

  },
};
</script>
