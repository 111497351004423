//import Socket from "@/services/Socket";
import AppDataServ from '@/services/AppDataServ';
import { dateFormatDdMmYyyy } from '@/helpers/formating';

export const ModuleOrder = () => {
  return {
    namespaced: true,

    state: () => ({
      order: {
        readyState: false,
      },

      customerInfo: {
        readyState: false,
      },

      oneTimeServices: {
        readyState: false,
      },

      services: {
        readyState: false,
      },

      files: {
        act: [],
        blanc: [],
        doc: [],
        works: [],
      },

      SECTION_ID: null,

      ORDER_ID: null,
    }),

    mutations: {
      CLEAR_STATE(state) {
        state.order = {
          readyState: false,
        };

        state.customerInfo = {
          readyState: false,
        };

        state.oneTimeServices = {
          readyState: false,
        };

        state.services = {
          readyState: false,
        };

        state.files = {
          act: [],
          blanc: [],
          doc: [],
          works: [],
        };

        state.SECTION_ID = null;

        state.ORDER_ID = null;
      },

      SET_ORDER_DETAILS(state, data) {
        state.order.details = data[0];
        state.order.readyState = true;
      },

      SET_CUSTOMER_INFO(state, data) {
        if ( data.birthdayDate ) {
          data.birthdayDate = dateFormatDdMmYyyy(data.birthdayDate);
        }

        if ( data.issueDate ) {
          data.issueDate = dateFormatDdMmYyyy(data.issueDate);
        }

        if ( data.passportDetails ) {
          const pass = data.passportDetails.split(' ');
          data.passportSeries = pass[0];
          data.passportNumber = pass[1];
        } else {
          data.passportSeries = '';
          data.passportNumber = '';
        }

        state.customerInfo = data;
        state.customerInfo.UBN = state.order.details.CustomerUBN;
        state.customerInfo.readyState = true;
      },

      SET_DOWNLOADED_FILES(state, data) {
        data.forEach(el => {
          el.loaded = true;
          state.files[el.type_name].push(el);
        });
      },

      SET_HYDRA_TYPES_SERVICES(state, data) {
        const t = state.services.fromHydra.find(({ typeOfService }) => +typeOfService === +data.typeOfService);

        t.baseContractHydraId = data.baseContractHydraId;
        t.serviceTypeId = data.serviceTypeId;

        if ( data.avaliableServices ) {
          t.tariffList = data.avaliableServices || [];
        }

        if ( data.avalibleContracts ) {
          t.contractsList = data.avalibleContracts || [];

          t.contractsList.unshift({
            name: 'Выбрать контракт',
            value: '',
          });
        }
      },

      SET_HYDRA_SERVICES(state, data) {
        state.services.fromHydra = [];

        data.forEach(i => {
          if ( Object.keys(i.accountData).length !== 0 ) {
            state.services.account = i.accountData;
          }

          const hasHydraConnection = Object.keys(i.connectionData).length !== 0;

          state.services.fromHydra.push({
            connectionData: hasHydraConnection ? i.connectionData : '',
            name: i.name,
            typeOfService: i.typeOfService,
            billingStart: hasHydraConnection,
            isConnected: false,
            isOpened: hasHydraConnection,
            showUploader: false,
            tariffList: [],
            contractsList: [],
          });
        });

        state.services.readyState = true;
      },

      SET_HYDRA_TARIFFICATION(state, { params, serviceData, accountData }) {
        const t = state.services.fromHydra.find(({ typeOfService }) => +typeOfService === +params.mainServiceSdId);

        t.connectionData = {
          name: params.serviceName,
          amount: params.serviceAmount,
          type_name: params.serviceTypeName,
          login: serviceData.serviceLogin,
          pass: serviceData.servicePass,
          personalAccountNumber: serviceData.personalAccountNumber,
        };

        state.services.account = {
          login: accountData.accountLogin,
          pass: accountData.accountPass,
        };
      },

      SET_HYDRA_TARIFFICATION_PHONE(state, {
        mainServiceSdId,
        tariffZone,
        serviceTypeName,
        serviceName,
        serviceAmount,
        operatorContractName,
        pbxName,
      }) {
        const t = state.services.fromHydra.find(({ typeOfService }) => +typeOfService === +mainServiceSdId);

        t.connectionData = {
          tariff_zone: tariffZone,
          type_name: serviceTypeName,
          name: serviceName,
          amount: serviceAmount,
          operator_contract_name: operatorContractName,
          pbx_name: pbxName,
        };
      },

      SET_ONE_TIME_SERVICES_CONNECTED(state, { account, connected, servicesList }) {
        state.oneTimeServices.connected = connected;

        const list = [];

        servicesList.forEach(el => {
          const selected = state.oneTimeServices.connected.find(({ serviceId }) => +serviceId === +el.id);

          if ( selected ) {
            el.isSelected = true;
            el.costId = selected.costId;
            el.sum = el.costs.find(({ id }) => +id === +selected.costId).cost || 0;
          } else {
            el.isSelected = false;
            el.costId = '';
            el.sum = 0;
          }

          list.push(el);
        });

        state.oneTimeServices.list = list;
        state.oneTimeServices.account = account;
        state.oneTimeServices.readyState = true;
      },

      SET_SD_SERVICES(state, data) {
        state.services.fromSD = data;
      },

      UPDATE_FILES(state, data) {
        data.loaded = false;

        state.files[data.type_name].push(data);

        setTimeout(() => state.files[data.type_name].find(el => el.file_id === data.file_id).loaded = true, 1000);
      },

      UPDATE_ONE_TIME_SERVICES(state, data) {
        console.log(data);
        state.oneTimeServices.account = data;
      },

      UPDATE_ORDER_MEETING(state, status) {
        state.order.details.meetingStatusId = status;
      },
      UPDATE_ORDER_DATETIME(state, { keyTime, time }) {

        state.order.details[keyTime] = time;
      },
    },

    actions: {
      fetchCustomerInfo({ commit }, { customerId, dealId }) {
        return AppDataServ.getCustomerInfo(customerId, dealId)
          .then(response => {
            commit('SET_CUSTOMER_INFO', response.data);
          })
          .catch(error => {
            throw(error);
          });
      },

      fetchDownloadedFiles({ commit }, orderId) {
        return AppDataServ.getDownloadedFiles(orderId)
          .then(response => {
            commit('SET_DOWNLOADED_FILES', response.data);
          })
          .catch(error => {
            throw(error);
          });
      },

      fetchHydraServices({ commit }, dealId) {
        return AppDataServ.getHydraServices(dealId)
          .then(response => {
            commit('SET_HYDRA_SERVICES', response.data);
          })
          .catch(error => {
            throw(error);
          });
      },

      fetchOneTimeServicesConnected({ commit, state }, { dealId, servicesList }) {
        if ( !state.oneTimeServices.readyState ) {
          console.log('get connected one time services');
          return AppDataServ.getOneTimeServicesConnected(dealId)
            .then(response => {
              console.log(response.data);
              commit('SET_ONE_TIME_SERVICES_CONNECTED', {
                account: response.data.oneTimeAccount || '',
                connected: response.data.services || [],
                servicesList,
              });
            })
            .catch(error => {
              throw(error);
            });
        }
      },

      fetchSDServices({ commit }, dealId) {
        return AppDataServ.getSDServices(dealId)
          .then(response => {
            commit('SET_SD_SERVICES', response.data);
          })
          .catch(error => {
            throw(error);
          });
      },

      fetchOrderDetails({ commit }, orderId) {
        return AppDataServ.getOrderDetails(orderId)
          .then(response => {
            commit('SET_ORDER_DETAILS', response.data);
          })
          .catch(error => {
            throw(error);
          });
      },

      setTariffication({ commit }, params) {
        return AppDataServ.setTariffication(params)
          .then(res => {
            commit('SET_HYDRA_TARIFFICATION', {
              accountData: res.data.accountData,
              serviceData: res.data.serviceData,
              params: params,
            });
          })
          .catch(error => {
            throw(error);
          });
      },

      setTarifficationPhone({ commit }, params) {
        return AppDataServ.setTarifficationPhone(params)
          .then(() => {
            commit('SET_HYDRA_TARIFFICATION_PHONE', params);
          })
          .catch(error => {
            throw(error);
          });
      },

      setHydraConnection(store, params) {
        return AppDataServ.createSubscriber(params)
          .catch(error => {
            throw(error);
          });
      },

      setOneTimeServices({ commit }, params) {
        return AppDataServ.setOneTimeServices(params)
          .then(response => {
            console.log(response);
            commit('UPDATE_ONE_TIME_SERVICES', response.data.oneTimeAccount);
            return response.data.status;
          })
          .catch(error => {
            throw(error);
          });
      },

      /*			socketRegisterOrder(store, orderId) {
				return Socket.setOrder(orderId);
			},*/

      updateCustomerInfo(store, customerData) {
        return AppDataServ.updateCustomerInfo(customerData)
          .then(response => {
            return response.status;
          })
          .catch(error => {
            throw(error);
          });
      },

      updateCustomerUBN(store, customerSdId) {
        // return new Promise((resolve) => {
        //   setTimeout(() => {
        //     resolve({ status: 1, ubn: 0 });
        //   }, 10000);
        //
        // });

        return AppDataServ.updateCustomerUBN(customerSdId)
          .then(response => {
            console.log('Гидра-биндинг ответ получен:');
            console.log(response);
            return response.data;
          })
          .catch(error => {
            throw(error);
          });
      },

      updateMeetingDateTime({ commit }, params) {
        //
        // console.log('updateMeetingDateTime', params);

        return AppDataServ.updateMeetingDateTime(params)
          .then(response => {
            if ( parseInt(response.data) === 1 ) {
              commit('UPDATE_ORDER_MEETING', params.status);
              // commit('UPDATE_ORDER_MEETING', params.status - 1);
              commit('UPDATE_ORDER_DATETIME', { keyTime: params?.keyTime || 'meetingDateTime', time: params.time });
            }
          })
          .catch(error => {
            throw(error);
          });
      },


      updateTypeServices({ commit }, params) {
        return AppDataServ.updateTypeServices(params)
          .then(response => {
            response.data.serviceTypeId = params.typeOfMainService;
            commit('SET_HYDRA_TYPES_SERVICES', response.data);
          })
          .catch(error => {
            throw(error);
          });
      },

      uploadFileToServer({ commit }, params) {
        const formData = new FormData();
        formData.append('customerId', params.customerId);
        formData.append('dealId', params.dealId);
        formData.append('orderId', params.orderId);
        formData.append('type', params.type);

        if ( params.workId ) formData.append('workId', params.workId);

        formData.append('file', params.file);

        return AppDataServ.uploadFileToServer(formData)
          .then(response => {
            commit('UPDATE_FILES', response.data);
          })
          .catch(error => {
            throw(error);
          });
      },

      fetchWorkDownloadedFiles({ commit }, workId) {
        return AppDataServ.getWorksDownloadedFiles(workId)
          .then(response => {
            // add type_name
            const files = response.data.map(file => {
              return { ...file, ...{ type_name: 'works' } };
            });

            commit('SET_DOWNLOADED_FILES', files);
          })
          .catch(error => {
            throw(error);
          });
      },


    },

    getters: {
      passTypes: state => {
        const passTypes = state.customerInfo?.passTypes || [];

        return [ ...passTypes ].map(pass => ({
            id: pass?.PassTypeId,
            value: pass?.PassTypeName,
          }),
        );
      },

      customerPassId: state => {
        return state.customerInfo?.passTypeId;
      },

      customerPassText: (state, getters) => {
        if ( getters.customerPassId ) {
          return getters.passTypes.find(pass => pass.id === getters.customerPassId)?.value;
        }

        return null;
      },
    },
  };
};
