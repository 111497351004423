import {ref, computed} from 'vue';

const apiError = ref(null);

const isLoading = ref(false);

export default function useApiStatus() {

  const setLoading = (loadingProp) => {
    isLoading.value = loadingProp
  }

  const isError = computed(() => apiError.value !== null);

  const setError = ({code, message}) => {
    apiError.value = {
      code, message
    }
  }

  const clearError = () => {
    apiError.value = null;
  }

  return {
    isLoading,
    apiError,
    isError,
    setLoading,
    setError,
    clearError
  }
}