export const inputOptions = () => {
  return {
    error: {
      classApproval: '',
      classList: 'fn fn-error',
      icon: '--elzMsk: url(\'/style/icons/info.svg\');',
      tooltip: 'fn fn-error bgHov bgHov-error fnHov-error-t',
    },
    success: {
      classApproval: 'isValidValue',
      classList: 'isValidValue fn fn-success',
      icon: '--elzMsk: url(\'/style/icons/checkmark.svg\')',
      tooltip: 'fn fn-success bgHov bgHov-success fnHov-success-t',
    },
    waiting: {
      classApproval: 'isValidValue',
      classList: 'isValidValue fn fn-primary-t',
      icon: '--elzMsk: url(\'/style/icons/clock.svg\')',
      tooltip: 'fn fn-primary-t bgHov bgHov-primary-t fnHov-primary',
    },
  };
};

export const inputValidation = () => {
  return {
    email: {
      mask: 'E*',
      tokens: {
        'E': {
          pattern: /^[!#$%&'*+/=?^_`{|}~@\-.a-zA-Z0-9\s]+/,
        },
      },
    },
    text: {
      mask: 'T*',
      tokens: {
        'T': {
          pattern: /^[/-_,.№#'"*()XxIi:а-яА-ЯёЁ0-9\s]+/u,
        },
      },
    },
    word: {
      mask: 'RR*',
      tokens: {
        'R': {
          pattern: /[а-яёА-ЯЁ]+/u,
        },
      },
    },
  };
};

export const tooltipOptions = (type) => {
  const options = [
    {
      type: 'success',
      classBody: 'bg-success fn-success-t',
      classTail: 'fn-success',
    },
    {
      type: 'ok',
      classBody: 'bg-ok fn-ok-t',
      classTail: 'fn-ok',
    },
    {
      type: 'error',
      classBody: 'bg-error fn-error-t',
      classTail: 'fn-error',
    },
  ];

  return options.find(o => o.type === type);
};
