<template>
  <div class="elz d-flex r3 p8 gap8 bor1 bg bg-primary bgL5 bgLInvD br br-primary brL-10 brLInvD">
    <div class="elz d-flex f-wrap m-8 p8 a-H growX gap8 opAct07">
      <div class="elz d-flex a-X s32">
        <div
            class="elz p-rel d-block p-rel mskBef s16 cFillBef bgBef-CC"
            :style="`--elzMsk: url('/style/icons/doc-eula.svg');`"
        />
      </div>
      <div class="elz d-block growX wbreak">
        <div class="elz d-block">
          {{ fileItem.file_name }}
        </div>
      </div>
    </div>

    <div
        v-if="+fileItem.tts_upload === 0"
        title="Ошибка загрузки файла в ТТС"
        class="elz d-flex s-H a-X cur-pointer opAct07 s32 rRound bgHov bgHov-primary bgHovL-5 bgHovLInvD"
    >
      <div
          class="mskIcon elz d-block p-rel noShrink mskBef s16 cFillBef bgBef-CC"
          style="--elzMsk: url('/style/icons/info.svg');"
      />
    </div>

    <div
        v-if="!fileIsLoaded"
        title="Идет загрузка файла"
        class="elz d-flex s-H a-X cur-pointer opAct07 s32 rRound bgHov bgHov-primary bgHovL-5 bgHovLInvD"
    >
      <div
          class="mskIcon elz d-block p-rel noShrink mskBef s16 cFillBef bgBef-CC an-spinner anim8 anCount-infinite animit"
          style="--elzMsk: url('/style/icons/spinner.svg');"
      />
    </div>

    <a
        v-else
        :download="`${fileItem.file_name}`"
        :href="compileURL"
        target="_blank"
        title="Скачать"
        class="elz d-flex s-H a-X cur-pointer opAct07 s32 rRound bgHov bgHov-primary bgHovL-5 bgHovLInvD"
    >
      <div
          class="mskIcon elz d-block p-rel noShrink mskBef s16 cFillBef bgBef-CC"
          style="--elzMsk: url('/style/icons/save.svg');"
      />
    </a>
  </div>
</template>

<script>
export default {
  name: 'FileUploadWrapItem',

  props: {
    fileItem: { required: true, type: Object },
  },

  computed: {
    fileIsLoaded() {
      return this.fileItem.loaded;
    },

    baseUrl() {
      return this.$store.state.static.baseUrl;
    },

    compileURL() {
      if ( !this.baseUrl ) {
        this.$store.dispatch('static/getApiBaseUrl');
      }

      let url = this.baseUrl;
      url += `/fileLoader/getFileById/${ this.fileItem.file_id }?jwt=`;
      url += localStorage.getItem('engineerAccountAppToken');

      return url;
    },
  },
};
</script>
