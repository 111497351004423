<template>
  <div>
    <div class="elz d-block grow">
      <Datepicker
          v-model="date"
          locale="ru-RU"
          auto-apply
          inline
      />
      <div class="elz d-flex a-X mT10 gap8">
        <div
            class="elz d-block r3 pV8 pH16 cur-pointer opAct07 bg bg-primary bgHovL-10"
            @click="clearDatepicker"
        >
          Сбросить
        </div>
        <div
            class="elz d-block r3 pV8 pH16 cur-pointer opAct07 bg bg-ok bgHovL10 fn fn-ok-t"
            @click="setDatepicker"
        >
          Сохранить
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const props = defineProps({
  defaultDate: {
    type: [ String, null ],
    required: false,
    default: null,
  },
});

const emits = defineEmits([ 'datepickerDate', 'clear' ]);

const date = ref(props.defaultDate ? new Date(props.defaultDate) : new Date());

const clearDatepicker = () => {
  date.value = new Date();
  emits('clear');
};

const setDatepicker = () => {
  const newDate = `${ date.value.getFullYear() }-${ defineLeadZero(date.value.getMonth() + 1) }-${ defineLeadZero(date.value.getDate()) } ${ defineLeadZero(date.value.getHours()) }:${ defineLeadZero(date.value.getMinutes()) }:00.000`;
  console.log('newDate', newDate);
  emits('datepickerDate', newDate);
};

const defineLeadZero = (number) => {
  return number.toString().padStart(2, '0');
};


</script>


<style src="../../assets/style/datepicker/dp.css"></style>
